import React from 'react'
import img from "./assets/BANNER.jpg";
import church2 from "./assets/church 2.jpg";
export default function History() {
  const departments = [
    "Pastoral Concerns",
    "Mission and Evangelism",
    "Communications",
    "Dalit and Adivasi Concerns",
    "Christian Education",
    "Youth Concerns",
    "CSI-SEVA",
    "Ecological Concerns",
  ];
  return (
    <div>
        <div
        className="w-full mx-auto px-9 py-8 bg-cover bg-center"
        style={{ backgroundImage: `url(${img})` }} // Set the local image as the background
      >
        <div className="flex items-center text-black">
          {/* Home SVG Icon */}
          <a
            href="#"
            className="flex font-semibold text-lg items-center text-gray-900  transition-colors duration-300"
          >
            <svg
              className="w-4 h-4 mr-2"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
            </svg>
            Home
          </a>
          <span className="mx-2">/</span>
          <h2 className="text-[#881337]">HISTORY CSI</h2>
        </div>
      </div>
      <div className="bg-white px-6  bg-opacity-80  rounded-lg ">
      <h2 className="text-2xl  mt-6 py-6 font-semibold text-[#881337] ">
      HISTORY OF CHURCH OF SOUTH INDIA
          </h2>
        </div>
      <div className="flex flex-col px-6 py-2   lg:flex-row lg:items-center lg:max-w-screen-7xl">
            <div className="lg:w-2/3 lg:pr-10">
              <p className="text-gray-700 text-lg ">
              The emergence of the Church of South India from and out of several conflicting denominations and sects into one united Church was acclaimed as the second greatest miracle in the history of the Church, next only to the event of Pentecost which gave birth to the Church itself. Different Church groups from the West, which even to this day, do not have much in common, were able to leave behind their distinguishing differences, in order to forge themselves as one Church, with a distinct new identity as an independent Indian Church in the Independent India free from the "colonial stamp and mark" which it carried earlier. This significant move took place for a well considered purpose of Witness and Service by the Indian Church with indigenous resources. Through this, the Church of South India had provided to the worldwide Church, a model for meaningful ecumenical fellowship overcoming petty differences in faith and order and presenting itself as a matured Church with its own national identity.
              </p>
              

            </div>

            <div className="lg:w-1/3 lg:pl-10 mt-8 lg:mt-0">
              <div className="flex justify-center lg:justify-end">
                <img
                  className="h-72 w-auto rounded-xl object-contain shadow-lg"
                  src={church2}
                  alt="About Us"
                />
              </div>
            </div>
          </div>


          <div className="bg-white   bg-opacity-80 p-6 rounded-lg shadow-lg">
       

        <section className="mb-6">
         
          <p className="text-gray-700  text-lg mt-4">
          The beginning to this lofty achievement was made in the year 1919 when 33 Indian Church leaders met together in the New Jerusalem Church in Tranquebar and gave a clarion call to the divided denominational Churches (of European origin) in India to unite together as one Indian Church. The painstaking efforts of negotiations and consultations lasted over twenty eight long years both in India and abroad at the Churches and Mission Board's headquarters and at the level of the local congregations.
          </p>
          <p className="text-gray-700 text-lg mt-4">
          The Church leaders had to deal with the variety of legal, administrative and theological issues. They also had to find solutions to the problems of property management, administration and leadership, jurisdiction and governance, acceptable Doctrine. Common Liturgy, Order of Ministries, etc. They had to arrive at a common Constitution and Bye-laws and set a democratic process of governance. And finally on the 27th September 1947, the Church of South India emerged as an Indian Church, bringing together with it Missionary Societies and Churches from four different denominational Church traditions namely, The Anglicans, The Presbyterians, The Congregationalists and the Wesleyan Methodists. This United Church also brought together in Communion, a large family of Christians superseding caste and class considerations. This fellowship was further enriched and enlarged as the Churches from the Basel Mission tradition joined the Union of the Church of South India later.
          </p>
          <h2 className="text-2xl  mt-4 py-4 font-semibold text-[#881337] ">
          The Church of South India (CSI) – Today
          </h2>
          <p className="text-gray-700 text-lg mt-4">
          The Church of South India spreads over the South Indian States of Andhra Pradesh, Telungana, Tamilnadu, Karnataka, Kerala and Pondicherry. The Church also has a Diocese in Jaffna, Sri Lanka. The Church has congregations in many parts of North America, the United Kingdom, Europe, Australia, in the different Gulf Countries and also in Singapore and Malaysia. The Church has over 4.5 million people as members in 15,000 congregations served by almost 3500 Presbyters of whom over two hundred are women Presbyters. The Church also has a woman bishop.
          </p>
          <p className="text-gray-700 text-lg mt-4">
          The Church of South India spreads over the South Indian States of Andhra Pradesh, Telungana, Tamilnadu, Karnataka, Kerala and Pondicherry. The Church also has a Diocese in Jaffna, Sri Lanka. The Church has congregations in many parts of North America, the United Kingdom, Europe, Australia, in the different Gulf Countries and also in Singapore and Malaysia. The Church has over 4.5 million people as members in 15,000 congregations served by almost 3500 Presbyters of whom over two hundred are women Presbyters. The Church also has a woman bishop.
          </p>

  <p className="text-gray-700 text-lg mt-4">
          The overall governance of the Church of South India vests with the Synod of the Church of South India which is the apex legislative and policy making body. The properties both movable and immovable are governed and administered through a Trust, known as the Church of South India Trust Association (CSITA), which exists and functions under the authority of the Synod of the Church of South India. The Church of South India continues to grow. Many independent Churches appeal to the Synod Leadership wishing to become members of this great Church.
</p>

<h2 className="text-2xl  mt-4 py-4 font-semibold text-[#881337] ">
The Ecumenical Outlook of the CSI
          </h2>
          <p className="text-gray-700 text-lg">
          The Church of South India is a worldwide fellowship. The Church is in partnership with worldwide ecumenical bodies. Thereby, the quality of its ministry is constantly enriched and its outlook widened. The Church of South India has been a member of the World Council of Churches (WCC), Christian Conference of Asia (CCA), World Communion of Reformed Churches (WCRC), Global Anglican Communion, Evangelical Mission in Solidarity (EMS), Presbyterian Churches in the Republic of Korea, Uniting Church in Australia, etc. It has been partnering with worldwide service agencies such as ECLOF, Christian Aid, Compassion India, Episcopal Relief and Development, Bread for the World, KNH etc.
</p>
<h2 className="text-2xl  mt-2 py-4 font-semibold text-[#881337] ">
Winding of the Ecumenical Co-operation within India
          </h2>
          <p className="text-gray-700 text-lg">
          The union that began with the Episcopal and non-Episcopal stand of the Church through the union of the Church of South India, continues to grow with a search for a more meaningful and relevant union. Together with the Church of North India (CNI) and the Marthoma Syrian Church, a Communion of Churches in India has been formed. Many joint programmes are being organized on behalf of the Council of Churches.
</p>

<h2 className="text-2xl  mt-4 py-4 font-semibold text-[#881337] ">
Special Thrust in Ministry and Mission
</h2>

<p className="text-gray-700 text-lg  ">
In order to provide a greater impact to special areas of concern, the Synod of the Church of South India has constituted Councils and Departments headed by a Director or a Convener to each of these Councils/Departments. The following Departments serve as special areas of concern in the CSI.<br/>
They are:
</p>
<ul className="space-y-4 mt-4">
      {departments.map((department, index) => (
        <li key={index} className="flex items-center space-x-2">
           <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-4 h-4 text-gray-700"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5l7 7-7 7"
            />
          </svg>
          <span className="text-lg font-medium text-gray-900">{department}</span>
        </li>
      ))}
    </ul>
    <p className="text-gray-700 text-lg mt-6  ">
    Besides these, there are a number of other Committees in the CSI, promoting a variety of ministries.
    </p>
        </section>
        <h2 className="text-2xl  mt-4 py-4 font-semibold text-[#881337] ">
        The Women's Fellowship
</h2>
<p className="text-gray-700 text-lg">
Right from the formation of the Church of South India, the Women's Fellowship has been an integral part of the Church. The CSI Women's Fellowship functions through the Diocesan and Area level Women's Fellowships. The women are engaged in activities not only strengthening the ministry of the Churches but also involved in special ministries in the community taking active interest in Child Care, Women and Gender Issues, Concern towards Women in Crisis, etc. The work of the CSI Women's Fellowship is co-ordinated by the General Secretary of the Women's Fellowship.
</p>


<h2 className="text-2xl  mt-4 py-4 font-semibold text-[#881337] ">
The Order of Sisters
</h2>

<p className="text-gray-700 text-lg">
The Order of Sisters of the Church of South India is a special ministry of women called to a special way of life within the Church. Spinsters with a deep sense of commitment to a life of prayer and the study of Word of God constitute the Order of Sisters. They assist in the ministry of the Church in different Dioceses in several different ways as Pastors, Heads of Educational Institutions, in medical work, in Child Care work in special ministries such as work among differently abled persons, etc. The Order of Sisters is similar to the Order of Presbyters in the Church, respond to their calling to promote and strengthen the ministry of the Church.
</p>


<h2 className="text-2xl  mt-4 py-4 font-semibold text-[#881337] ">
The Church's Commitment to the Community
</h2>

<p className="text-gray-700 text-lg">
As our Master Jesus Christ lived for others, the Church primarily lives and functions for the sake of others. It was Arch Bishop William Temple, who once said that Church is the only Organisation which does not exist for its members.
</p>
<p className="text-gray-700 text-lg">

The foundation of the Indian Church rests on its ministries of Compassion and Charity. The former Chief Minister of Karnataka Sri S.M. Krishna once in public address stated: "When I tour around my State, whenever I see a Cross in some remote village or town, then I know that there is some good work going on there". These statements aptly describe the services of the CSI to the Community. The Church of South India is one of the most significant social Institutions in the country providing quality education and health care to millions of people both in the cities and in rural villages and towns. The CSI administers and efficiently runs hundreds of Colleges including professional Colleges, Polytechnics including Polytechnics specially designated for women, High Schools, Primary and Middle Schools mainly to cater to the needs of the growing masses in cities, towns and villages. The Church of South India also runs Hospitals in many parts of the country. Large Hospitals do have a School of Nursing or a College of Nursing attached to them. The Church of South India has also ventured into special areas of service especially for the differently abled, deaf, dumb, blind, etc. It also runs residential schools for the orphans, semi-orphans and the poor and destitute. A special area of concern is the Child Care Ministry including the care for the children in crisis. Special efforts are also made to address areas of concern such as women in crisis including the traditional devadasis. The Church is ever alert to take up new challenges emerging in the community and is available to handle them compassionately and competently.
</p>





        </div>
     
    </div>
  )
}
