import { doc, getDoc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import { db } from '../Config/Config';

export default function Kaniv() {
  const [data,setData] = useState({});
  useEffect(() => {
    fetchKaniv();
  }, [])
  const fetchKaniv = async () => {
    const docRef = doc(db, "kaniv-pdf", "kaniv-text");
    const docSnap = await getDoc(docRef);
    setData(docSnap.data());
  }
  return (
    <div>
   <section>

  <div className="flex flex-col items-center justify-center px-5 md:px-10">
  
  
    <div className="mx-auto w-full max-w-5xl py-12 md:py-12 lg:py-12">
      <p className="mb-5 text-2xl text-[#881337] font-extrabold uppercase">{data.title}</p>
      <div className="mb-6 min-h-[1px] min-w-full bg-[#e2e2e2]"></div>
   
      <div className="mb-6 flex flex-col items-start gap-y-3">
        {/* <p className="text-sm font-bold uppercase sm:text-base">Services</p> */}
        <p className="text-sm sm:text-base">{data.content}</p>
      </div>
    
     

    </div>
  </div>
</section>

    </div>
  )
}
