import React, { useEffect, useState } from 'react'
import img from '../Institutions/assets/BANNER.jpg'
import { collection, getDocs, orderBy, query } from 'firebase/firestore';
import { db } from '../Config/Config';
export default function Results() {
  // ================================================ Data fetching ============================================= //
  const [showData, setShowData] = useState([]);
  const fetchData = async () => {
    const q = query(collection(db, "results"), orderBy("order", "asc"));
    const querySnapshot = await getDocs(q);
    const data = [];
    querySnapshot.forEach((doc) => {
      data.push({
        id: doc.id,
        ...doc.data(),
      });
    });
    setShowData(data);
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div>
      <div
        className="w-full mx-auto px-9 py-8 bg-cover bg-center"
        style={{ backgroundImage: `url(${img})` }}
      >
        <div className="flex items-center text-black">
          <a
            href="/"
            className="flex font-semibold text-lg items-center text-gray-900  transition-colors duration-300"
          >
            <svg
              className="w-4 h-4 mr-2"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
            </svg>
            Home
          </a>
          <span className="mx-2">/</span>
          <h2 className="text-[#881337]">RESULTS</h2>
        </div>
      </div>
      <section>
        <div className="mx-auto w-full max-w-7xl px-5 py-10 md:px-10">
          <section>
            <div className="mx-auto w-full max-w-7xl px-5 py-10 md:px-10">
              <h4 className="text-xl md:text-3xl font-bold mb-2 text-gray-950">
                Results
              </h4>
              <p className="text-gray-700 mb-10">CSI Malabar Diocese Results</p>

              {showData &&
                showData.map((data) => {
                  return (
                    <div className="grid grid-cols-1 md:grid-cols-8 gap-4 py-7 border-b border-gray-300">
                      <div className="font-semibold text-gray-950 md:col-span-2">
                        {new Date(
                          data.date.seconds * 1000
                        ).toLocaleDateString()}
                      </div>
                      <div className="text-gray-700 md:col-span-5">
                        {" "}
                        {data.contest}
                        <br />
                        {data.prize.length > 0 &&
                          data.prize.map((prize) => (
                            <p className="mt-2">{prize}</p>
                          ))}
                      </div>
                      <div className="font-medium text-end text-[#881337] md:col-span-1 cursor-pointer">
                        <a
                          href={data.url}
                          className="cursor-pointer"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          View Results
                        </a>
                      </div>
                    </div>
                  );
                })}
            </div>
          </section>
        </div>
      </section>
    </div>
  );
}
