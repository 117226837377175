import React from 'react';
import { useSpring, animated } from '@react-spring/web';
import img from './kl.png'; // Ensure this path is correct

export default function Status() {
  const springs = useSpring({
    from: { opacity: 0, transform: 'translateY(20px)' },
    to: { opacity: 1, transform: 'translateY(0px)' },
    delay: 200,
    config: { tension: 200, friction: 15 },
  });

  return (
    <div className="relative max-w-7xl   mx-auto px-4 py-5 md:py-10 px-5 md:px-10">
      {/* Background Image with Blur and Faded Overlay */}
      <div
        className="absolute inset-0 bg-cover bg-center"
        style={{ backgroundImage: `url(${img})`, zIndex: -1 }}
      >
        {/* Faded Overlay */}
        <div className="absolute inset-0 bg-black opacity-10 backdrop-blur-md"></div>
      </div>

      <div className="relative z-20 max-w-7xl mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 items-center justify-between gap-5 sm:gap-8 md:gap-10 lg:gap-12 py-5 md:py-10 px-5 md:px-10">
        {[
          { value: '2', label: 'States' },
          { value: '5', label: 'Districts' },
          { value: '84', label: 'Churches' },
          { value: '49', label: 'Presbyters' },
          { value: '27', label: ' workers' },
          { value: '40500', label: 'Members' },
        ].map((stat, index) => (
          <animated.div
            key={index}
            style={springs}
            className="flex flex-col  items-center justify-center gap-y-2 bg-black bg-opacity-50 rounded-xl p-4 md:p-6 border border-white border-opacity-30"
          >
            <h2 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl text-white font-bold">{stat.value}</h2>
            <p className="text-center text-sm sm:text-base md:text-lg text-white">{stat.label}</p>
          </animated.div>
        ))}
      </div>
    </div>
  );
}
