import React, { useEffect, useState } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { collection, getDocs, orderBy, query } from 'firebase/firestore';
import { db } from '../Config/Config';
import { useNavigate } from 'react-router-dom';



export default function News() {
  // ========================================== NEWS CAROUSEL ========================================== //
  const [newsList, setnewsList] = useState([]);
  const navigate = useNavigate();
  const fetchNews = async () => {
    const data = [];
    const q = query(collection(db, "news"),orderBy('order', 'asc'));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      data.push({
        id: doc.id,
        ...doc.data(),
      });
      setnewsList(data);
    });
  }

  useEffect(() => {
    fetchNews();
  }, []);
 function truncateText(text, maxLength) {
   if (text.length > maxLength) {
     return text.slice(0, maxLength) + "...";
   }
   return text;
 }
  return (
    <section>
      <div className="mx-auto w-full max-w-7xl px-5 py-6 md:px-6 md:py-6">
        <h2 className="text-center mt-6 text-2xl  md:text-5xl lg:text-left">
          The latest and greatest news
        </h2>
        <p className=" mt-4 text-center text-sm text-gray-500 sm:text-base md:mb-12 lg:mb-16 lg:text-left">
          Lorem ipsum dolor sit amet elit ut aliquam
        </p>

        <div className="mx-auto grid gap-8 lg:grid-cols-2">
          {newsList.length > 0 && (
            <>
              {/* First News Article */}
              <div className="flex flex-col gap-4 rounded-md [grid-area:1/1/4/2] lg:pr-8">
                <img src={newsList[0].img} className='object-contain' alt="News" />
                <div className="flex flex-col items-start py-4">
                  <div className="mb-4 rounded-md bg-gray-100 px-2 py-1.5">
                    <p className="text-sm font-semibold text-black">
                      {new Date(
                        newsList[0].time_stamp.seconds * 1000
                      ).toLocaleDateString()}
                      ,&nbsp;
                      {new Date(
                        newsList[0].time_stamp.seconds * 1000
                      ).toLocaleString("en-US", {
                        hour: "numeric",
                        minute: "numeric",
                        hour12: true,
                      })}
                    </p>
                  </div>
                  <p className="mb-4 text-xl font-thin md:text-2xl">
                    {newsList[0].title}
                  </p>
                  <div className="flex flex-col text-sm text-gray-500 lg:flex-row">
                    <span
                      onClick={() =>
                        navigate("/readmore", { state: newsList[0] })
                      }
                      className="cursor-pointer"
                    >
                      Read More ....
                    </span>
                  </div>
                </div>

              </div>

              <div className="md:flex md:justify-between lg:flex-col">
                {newsList.slice(1).map((news, index) => (
                  <div
                    key={index}
                    className="flex flex-col pb-5 lg:mb-3 lg:flex-row lg:border-b lg:border-gray-300"
                  >
                    <img
                      src={news.img}
                      alt=""
                      className="inline-block h-60 mt-6 w-full object-cover md:h-32 lg:h-32 lg:w-32"
                    />
                    <div className="flex flex-col items-start pt-4 lg:px-8">
                      <div className="mb-2 rounded-md bg-gray-100 px-2 py-1.5">
                        <p className="text-sm font-semibold text-black">
                          {new Date(
                            news.time_stamp.seconds * 1000
                          ).toLocaleDateString()}
                          ,&nbsp;
                          {new Date(
                            news.time_stamp.seconds * 1000
                          ).toLocaleString("en-US", {
                            hour: "numeric",
                            minute: "numeric",
                            hour12: true,
                          })}
                        </p>
                      </div>
                      <p className="mb-2 text-sm font-thin sm:text-base">
                        {news.title}
                      </p>
                      <p className="mb-2 text-sm font-thin sm:text-base">
                        {truncateText(news.content, 50)} <br />
                      </p>
                      <div className="flex flex-col items-start">
                        <div className="flex flex-col text-sm text-gray-500 sm:text-base lg:flex-row lg:items-center">
                          <span
                            onClick={() =>
                              navigate("/readmore", { state: news })
                            }
                            className=" cursor-pointer"
                          >
                            Read More ....
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    </section>
  );
}
