import React from 'react';
import logo from './footer_logo.png';
import logo1 from './po.png';
import { ChevronRightIcon } from '@heroicons/react/24/solid'; // Import Heroicons ChevronRightIcon

export default function Footer() {
  return (
    <footer className="bg-[#881337]  text-gray-100">
      <div className="max-w-[85rem] py-12 px-4 sm:px-6 lg:px-8 mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-4"> {/* Adjusted gap */}
          
          <div className="flex flex-col items-start text-left">
            <img src={logo1} alt="Brand Logo" aria-label="Brand" className="w-28 h-auto mb-2" />
            <p className="text-lg mt-4 font-semibold">Church of South India</p>
            <p className="text-sm">churchofSouthindia@gmail.com</p>
          </div>

          <div className="text-left">
            <h4 className="font-semibold text-gray-100">Useful Links</h4>
            <div className="mt-3 space-y-2">
              <p className="flex items-center">
                <ChevronRightIcon className="w-5 h-5 text-gray-200 mr-2" />
                <a className="text-gray-100 hover:text-gray-200" href="/">Diocese Office Bearers</a>
              </p>
              <p className="flex items-center">
                <ChevronRightIcon className="w-5 h-5 text-gray-200 mr-2" />
                <a className="text-gray-100 hover:text-gray-200" href="#">Lights of our Diocese</a>
              </p>
            </div>
          </div>

          <div className="text-left">
            <h4 className="font-semibold text-gray-100">Services</h4>
            <div className="mt-3 space-y-2">
              <p className="flex items-center">
                <ChevronRightIcon className="w-5 h-5 text-gray-200 mr-2" />
                <a className="text-gray-100 hover:text-gray-200" href="/">Readings</a>
              </p>
              <p className="flex items-center">
                <ChevronRightIcon className="w-5 h-5 text-gray-200 mr-2" />
                <a className="text-gray-100 hover:text-gray-200" href="#">News & Events</a>
              </p>
              <p className="flex items-center">
                <ChevronRightIcon className="w-5 h-5 text-gray-200 mr-2" />
                <a className="text-gray-100 hover:text-gray-200" href="#">OUR Logo</a>
              </p>
              {/* <p className="flex items-center">
                <ChevronRightIcon className="w-5 h-5 text-gray-200 mr-2" />
                <a className="text-gray-100 hover:text-gray-200" href="#">Gallery</a>
              </p> */}
              <p className="flex items-center">
                <ChevronRightIcon className="w-5 h-5 text-gray-200 mr-2" />
                <a className="text-gray-100 hover:text-gray-200" href="#">Contact</a>
              </p>
            </div>
          </div>

          <div className="col-span-1 lg:col-span-2">
            <div className="flex flex-col items-start text-left">
              <img src={logo} alt="Stay updated" className="w-28 h-auto mb-4" />
              <div>
                <p className="text-lg font-semibold">Diocese of Malabar</p>
                <p className="text-sm">Bank Road, Calicut - 673 001</p>
                <p className="text-sm">0495 - 2721748, 2722748, 2723748</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="py-2 text-center bg-black text-gray-100">
        <p className="text-sm">© {new Date().getFullYear()} Church of South India. All rights reserved.</p>
      </div>
    </footer>
  );
}
