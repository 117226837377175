import React, { useEffect, useState } from 'react';
import img from './assets/BANNER.jpg';
import placeHolderChurch from "./assets/DUMMY.jpg";
import {collection, getDocs, orderBy, query} from 'firebase/firestore';
import { db } from '../Config/Config';
import { useNavigate } from 'react-router-dom';

export default function Churches() {
  // ============================================================ Fetch Data ===================================================== //
  const [showData, setShowData] = useState([]);
  const navigate = useNavigate();

  const fetchData = async () => {
    const q = query(collection(db, "churches"), orderBy("order", "asc"));
    const querySnapshot = await getDocs(q);
    const data = [];
    querySnapshot.forEach((doc) => {
      data.push({
        id: doc.id,
        ...doc.data(),
      });
    });
    setShowData(data);
  };
  useEffect(() => {
    fetchData();
  }, []);
  // ============================================================ Handle History ===================================================== //

  const handleHistory = (data) => {
    navigate("/church-history", {
      state: data,
    });
  };

  // ============================================================ Data Display ===================================================== //

  return (
    <div>
      <div
        className="w-full mx-auto px-8 py-8 bg-cover bg-center"
        style={{ backgroundImage: `url(${img})` }}
      >
        <div className="flex items-center text-black">
          <a
            href="/"
            className="flex font-semibold text-lg items-center text-gray-900 transition-colors duration-300"
          >
            <svg
              className="w-4 h-4 mr-2"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
            </svg>
            Home
          </a>
          <span className="mx-2">/</span>
          <h2 className="text-[#881337]">Churches</h2>
        </div>
      </div>

      <div className="container max-w-7xl mx-auto px-4">
        <h1 className="text-3xl font-normal mb-6 text-[#881337] mt-8">
          Churches Under Our Diocese
        </h1>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 py-2">
          {showData.map((church, index) => (
            <div key={index} className="bg-white border rounded-lg p-4">
              <img
                src={church.churchImg ? church.churchImg : placeHolderChurch}
                alt={church.churchName}
                className="w-full h-48 object-cover rounded-md mb-4"
              />
              <h2 className="text-xl font-semibold text-gray-800">
                {church.churchName}
              </h2>
              <p className="text-gray-600">{church.address}</p>
              {church.map_link ? (
                <a
                  href={church.map_link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-[#881337] hover:underline mt-4 flex items-center"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-geo-alt"
                    viewBox="0 0 16 16"
                  >
                    <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A32 32 0 0 1 8 14.58a32 32 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10" />
                    <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4m0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                  </svg>
                  <span className="ml-3 text-[#881337]">
                    View on Google Maps
                  </span>
                </a>
              ) : null}

              {/* History Icon Section */}
              {church.history ? (
                <div className="mt-4 flex items-center text-gray-600">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-book-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783" />
                  </svg>
                  <div
                    onClick={() => handleHistory(church)}
                    className="ml-3 cursor-pointer"
                  >
                    Historical Details Available
                  </div>
                </div>
              ) : null}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
