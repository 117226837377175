import React, { useRef, useState, useEffect } from 'react';
import { query, collection, getDocs } from 'firebase/firestore';
import { db } from '../Config/Config';

export default function ScrollNews() {
  const [newsItems, setNewsItems] = useState([]);
  const marqueeRef = useRef(null);

  const datafetching = async () => {
    const q = query(collection(db, "scroll-news"));
    const querySnapshot = await getDocs(q);
    const data = [];
    querySnapshot.forEach((doc) => {
      data.push({
        id: doc.id,
        ...doc.data()
      });
    });
    setNewsItems(data);
  };

  useEffect(() => {
    datafetching();
  }, []);

  return (
    <div className="overflow-hidden max-w-7xl mx-auto px-4 h-12 flex items-center">
      <div className="bg-gray-900 text-white px-6 flex-none h-full flex items-center">
        <span className="text-gray-100">Latest News</span>
      </div>
      <div className="bg-gray-200 flex-grow h-full flex items-center">
        <marquee
          behavior="scroll"
          direction="left"
          className="text-gray-700"
          ref={marqueeRef}
          onMouseOver={() => marqueeRef.current.stop()}
          onMouseOut={() => marqueeRef.current.start()}
        >
          {newsItems.map((newsItem, index) => (
            <React.Fragment key={newsItem.id}>
              <span>{newsItem.news}</span> 
              {index < newsItems.length - 1 && <span className="mx-4">|</span>}
            </React.Fragment>
          ))}
        </marquee>
      </div>
    </div>
  );
}
