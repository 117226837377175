import React, { useState } from "react";
import img from "./logo.png";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(null);
  const [openMobileDropdown, setOpenMobileDropdown] = useState(null);

  const toggleDropdown = (dropdown) => {
    setOpenDropdown(openDropdown === dropdown ? null : dropdown);
  };

  const toggleMobileDropdown = (dropdown) => {
    setOpenMobileDropdown(openMobileDropdown === dropdown ? null : dropdown);
  };

  return (
    <div>
    <div className="bg-[#881337]    text-white py-3">
      
  <div className="flex justify-between items-center px-6">

  <div className="flex items-center space-x-2">
  {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone" viewBox="0 0 16 16">
  <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.6 17.6 0 0 0 4.168 6.608 17.6 17.6 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.68.68 0 0 0-.58-.122l-2.19.547a1.75 1.75 0 0 1-1.657-.459L5.482 8.062a1.75 1.75 0 0 1-.46-1.657l.548-2.19a.68.68 0 0 0-.122-.58zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"/>
</svg>
      <span className="text-white ">0495 - 2721748,
      2722748, 2723748</span> */}
    </div>

    

    <div className="flex space-x-6">
  <a href="https://instagram.com" aria-label="Instagram">
    <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
      <path d="M7.75 2h8.5A5.75 5.75 0 0 1 22 7.75v8.5A5.75 5.75 0 0 1 16.25 22h-8.5A5.75 5.75 0 0 1 2 16.25v-8.5A5.75 5.75 0 0 1 7.75 2zm0 1.5A4.25 4.25 0 0 0 3.5 7.75v8.5A4.25 4.25 0 0 0 7.75 20.5h8.5a4.25 4.25 0 0 0 4.25-4.25v-8.5A4.25 4.25 0 0 0 16.25 3.5h-8.5zM12 7.25a4.75 4.75 0 1 1 0 9.5 4.75 4.75 0 0 1 0-9.5zm0 1.5a3.25 3.25 0 1 0 0 6.5 3.25 3.25 0 0 0 0-6.5zm5.25-.5a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
    </svg>
  </a>
  <a href="https://facebook.com" aria-label="Facebook">
    <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24">
      <path d="M22 12.1c0-5.5-4.5-10-10-10s-10 4.5-10 10c0 5 3.7 9.1 8.5 9.9v-7h-2.6v-2.9h2.6v-2.2c0-2.6 1.5-4 3.8-4 1.1 0 2.3.2 2.3.2v2.6h-1.3c-1.3 0-1.7.8-1.7 1.6v1.8h2.9l-.4 2.9h-2.5v7.1c4.8-.8 8.5-4.9 8.5-9.9z"></path>
    </svg>
  </a>
  <a href="https://youtube.com" aria-label="YouTube">
    <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24">
      <path d="M23.498 6.186a2.875 2.875 0 0 0-2.016-2.016C19.132 4 12 4 12 4s-7.132 0-9.482.17A2.875 2.875 0 0 0 .502 6.186C.34 6.938.257 7.701.257 8.473v7.053c0 .772.083 1.535.245 2.287a2.876 2.876 0 0 0 2.016 2.016C4.868 19.986 12 20 12 20s7.132 0 9.482-.17a2.875 2.875 0 0 0 2.016-2.016C23.92 17.06 24 16.298 24 15.527v-7.053c0-.772-.08-1.535-.245-2.287zM9.545 15.432v-6.884l6.135 3.442-6.135 3.442z"></path>
    </svg>
  </a>
</div>


   
  </div>
</div>

      <nav className="bg-white border-b  text-black">
        <div className="  max-w-7xl   mx-auto   px-4 sm:px-6 lg:px-8 ">
          <div className="flex  items-center justify-between h-16 md:h-20">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <img src={img} alt="Logo" className="h-16 w-auto" />
              </div>
              <div className="hidden text-sm lg:flex lg:ml-10">
                <a
                  href="/"
                  className="hover:text-[#881337] items-center px-3 py-2 rounded-md"
                >
                  HOME
                </a>

                <div
                  className="relative"
                  onMouseEnter={() => toggleDropdown("megamenu1")}
                  onMouseLeave={() => toggleDropdown(null)}
                >
                  <a href="/about-diocese" className="flex items-center hover:bg-white hover:text-[#881337] px-3 py-2 rounded-md">
                    ABOUT DIOCESE
                    <svg
                      className={`ml-2 w-4 h-4  transform transition-transform ${openDropdown === "megamenu1" ? "rotate-180" : ""}`}
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path d="M12 15.75l-6-6 1.5-1.5 4.5 4.5 4.5-4.5 1.5 1.5-6 6z"></path>
                    </svg>
                  </a>
                  <div
                    className={`absolute top-full left-0 z-50 bg-white border shadow-lg   w-80 p-4 transition-transform duration-300 ${openDropdown === "megamenu1" ? "scale-y-100 opacity-100" : "scale-y-0 opacity-0"}`}
                  >
                    <ul className="space-y-2">
                      <li className="border-b py-3">
                        <a
                          href="/about-diocese"
                          className="hover:text-[#881337] text-gray-800  text-[15px] block"
                        >
                          ABOUT DIOCESE
                        </a>
                      </li>
                      <li className="border-b py-3">
                        <a
                          href="/History"
                          className="hover:text-[#881337] text-gray-800  text-[15px] block"
                        >
                          HISTORY
                        </a>
                      </li>
                      <li className="border-b py-3">
                        <a
                          href="/Dioceseoffice"
                          className="hover:text-[#881337] text-gray-800  text-[15px] block"
                        >
                          DIOCESE OFFICE BEARERS
                        </a>
                      </li>
                      <li className=" py-3">
                        <a
                          href="/Lightsofourdiocese"
                          className="hover:text-[#881337] text-gray-800 text-[15px] block"
                        >
                          LIGHTS OF OUR DIOCESE
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                <a
                  href="/Churches"
                  className="hover:text-[#881337] px-3 py-2 rounded-md"
                >
                  CHURCHES
                </a>

                <div
                  className="relative"
                  onMouseEnter={() => toggleDropdown("megamenu2")}
                  onMouseLeave={() => toggleDropdown(null)}
                >
                  <a className="flex items-center hover:bg-white hover:text-black px-3 py-2 rounded-md">
                    INSTITUTIONS
                    <svg
                      className={`ml-2 w-4 h-4 transform transition-transform ${openDropdown === "megamenu2" ? "rotate-180" : ""}`}
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path d="M12 15.75l-6-6 1.5-1.5 4.5 4.5 4.5-4.5 1.5 1.5-6 6z"></path>
                    </svg>
                  </a>
                  <div
                    className={`absolute top-full left-0 z-50 bg-white border shadow-lg  w-60 p-4 transition-transform duration-300 ${openDropdown === "megamenu2" ? "scale-y-100 opacity-100" : "scale-y-0 opacity-0"}`}
                  >
                    <ul className="space-y-2">
                      <li className="border-b py-3">
                        <a
                          href="/Schools"
                          className="hover:text-[#881337] text-gray-800  text-[15px] block"
                        >
                          SCHOOLS
                        </a>
                      </li>
                      <li className="border-b py-3">
                        <a
                          href="/Collage"
                          className="hover:text-[#881337] text-gray-800 text-[15px] block"
                        >
                          COLLEGES
                        </a>
                      </li>
                      <li className="border-b py-3">
                        <a
                          href="/Hospital"
                          className="hover:text-[#881337] text-gray-800  text-[15px] block"
                        >
                          HOSPITALS
                        </a>
                      </li>
                      <li className="border-b py-3">
                        <a
                          href="/Retreatcenter"
                          className="hover:text-[#881337] text-gray-800  text-[15px] block"
                        >
                          RETREAT CENTER
                        </a>
                      </li>
                      <li className="border-b py-3">
                        <a
                          href="/Bookshop"
                          className="hover:text-[#881337] text-gray-800  text-[15px] block"
                        >
                          BOOK SHOP
                        </a>
                      </li>
                      <li className="border-b py-3">
                        <a
                          href="/Homes"
                          className="hover:text-[#881337] text-gray-800  text-[15px] block"
                        >
                          HOMES
                        </a>
                      </li>
                      <li className=" py-3">
                        <a
                          href="/Socialboards"
                          className="hover:text-[#881337] text-gray-800  text-[15px] block"
                        >
                          SOCIAL BOARD
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div
                  className="relative"
                  onMouseEnter={() => toggleDropdown("megamenu3")}
                  onMouseLeave={() => toggleDropdown(null)}
                >
                  <a  className="flex items-center hover:bg-white hover:text-black px-3 py-2 rounded-md">
                    PUBLICATIONS
                    <svg
                      className={`ml-2 w-4 h-4 transform transition-transform ${openDropdown === "megamenu3" ? "rotate-180" : ""}`}
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path d="M12 15.75l-6-6 1.5-1.5 4.5 4.5 4.5-4.5 1.5 1.5-6 6z"></path>
                    </svg>
                  </a>
                  <div
                    className={`absolute top-full left-0 z-50 bg-white border shadow-lg  w-60 p-4 transition-transform duration-300 ${openDropdown === "megamenu3" ? "scale-y-100 opacity-100" : "scale-y-0 opacity-0"}`}
                  >
                    <ul className="space-y-2">
                      <li className="border-b py-3">
                        <a
                          href="/Publication"
                          className="hover:text-[#881337] text-gray-800  text-[15px] block"
                        >
                          PUBLICATIONS
                        </a>
                      </li>
                      <li className=" py-3">
                        <a
                          href="/Readings"
                          className="hover:text-[#881337] text-gray-800  text-[15px] block"
                        >
                          READINGS
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                <a
                  href="/Departments"
                  className="hover:text-[#881337] px-3 py-2 rounded-md"
                >
                  DEPARTMENTS
                </a>
                <a
                  href="/Results"
                  className="hover:text-[#881337] px-3 py-2 rounded-md"
                >
                  RESULTS
                </a>
                <a
                  href="/Contact"
                  className="hover:text-[#881337] px-3 py-2 rounded-md"
                >
                  CONTACT US
                </a>
              </div>
            </div>

            {/* Hamburger Menu Button */}
            <div className="lg:hidden">
              <button
                onClick={() => setIsMenuOpen(!isMenuOpen)}
                className="p-2 rounded-md text-gray-800 hover:text-gray-800 focus:outline-none"
              >
                <svg
                  className={`h-6 w-6 transform transition-transform duration-300 ${
                    isMenuOpen ? "rotate-90" : "rotate-0"
                  }`}
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  ></path>
                </svg>
              </button>
            </div>
          </div>
        </div>

        {/* Mobile Menu */}
        {isMenuOpen && (
          <div className="lg:hidden mt-2">
            <a
              href="#"
              className="block px-4 py-2 text-gray-800 hover:bg-[#881337] hover:text-white"
            >
              HOME
            </a>

            <a
              onClick={() => toggleMobileDropdown("about")}
              className="w-full text-left block px-4 py-2 text-gray-800 hover:bg-[#881337] hover:text-white"
            >
              ABOUT DIOCESE
              <svg
                className={`ml-2 w-5 h-5 float-right inline-block transition-transform ${openMobileDropdown === "about" ? "rotate-180" : ""}`}
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M5.292 7.707a1 1 0 011.414 0L10 11.002l3.294-3.295a1 1 0 011.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </a>
            {openMobileDropdown === "about" && (
              <div className="bg-white text-sm  border-t border-b border-gray-200">
                <a
                  href="/aboutdiocese"
                  className="block px-4 py-2 text-gray-800 hover:bg-[#881337] hover:text-white"
                >
                  ABOUT DIOCESE
                </a>
                <a
                  href="/History"
                  className="block px-4 py-2 text-gray-800 hover:bg-[#881337] hover:text-white"
                >
                  HISTORY
                </a>
                <a
                  href="/Dioceseoffice"
                  className="block px-4 py-2 text-gray-800 hover:bg-[#881337] hover:text-white"
                >
                  DIOCESE OFFICE BEARERS
                </a>
                <a
                  href="/Lightsofourdiocese"
                  className="block px-4 py-2 text-gray-800 hover:bg-[#881337] hover:text-white"
                >
                  LIGHTS OF OUR DIOCESE
                </a>
              </div>
            )}
            <a
              href="/Churches"
              className="block px-4 py-2 text-gray-800 hover:bg-[#881337] hover:text-white"
            >
              CHURCHES
            </a>

            <a
              onClick={() => toggleMobileDropdown("institutions")}
              className="w-full text-left block px-4 py-2 text-gray-800 hover:bg-[#881337] hover:text-white"
            >
              INSTITUTIONS
              <svg
                className={`ml-2 w-5 h-5 float-right  inline-block transition-transform ${openMobileDropdown === "institutions" ? "rotate-180" : ""}`}
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M5.292 7.707a1 1 0 011.414 0L10 11.002l3.294-3.295a1 1 0 011.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </a>
            {openMobileDropdown === "institutions" && (
              <div className="bg-white text-sm border-t border-b border-gray-200">
                <a
                  href="/Schools"
                  className="block px-4 py-2 text-gray-800 hover:bg-[#881337] hover:text-white"
                >
                  SCHOOLS
                </a>
                <a
                  href="/Collage"
                  className="block px-4 py-2 text-gray-800 hover:bg-[#881337] hover:text-white"
                >
                  COLLEGES
                </a>
                <a
                  href="/Hospital"
                  className="block px-4 py-2 text-gray-800 hover:bg-[#881337] hover:text-white"
                >
                  HOSPITALS
                </a>
                <a
                  href="/Retreatcenter"
                  className="block px-4 py-2 text-gray-800 hover:bg-[#881337] hover:text-white"
                >
                  RETREAT CENTER
                </a>
                <a
                  href="/Bookshop"
                  className="block px-4 py-2 text-gray-800 hover:bg-[#881337] hover:text-white"
                >
                  BOOK SHOP
                </a>
                <a
                  href="/Homes"
                  className="block px-4 py-2 text-gray-800 hover:bg-[#881337] hover:text-white"
                >
                  HOMES
                </a>
                <a
                  href="/Socialboards"
                  className="block px-4 py-2 text-gray-800 hover:bg-[#881337] hover:text-white"
                >
                  SOCIAL BOARD
                </a>
              </div>
            )}

            <a
              onClick={() => toggleMobileDropdown("publications")}
              className="w-full text-left block px-4 py-2 text-gray-800 hover:bg-[#881337] hover:text-white"
            >
              PUBLICATIONS
              <svg
                className={`ml-2 w-5 h-5 float-right inline-block transition-transform ${openMobileDropdown === "publications" ? "rotate-180" : ""}`}
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M5.292 7.707a1 1 0 011.414 0L10 11.002l3.294-3.295a1 1 0 011.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </a>
            {openMobileDropdown === "publications" && (
              <div className="bg-white text-sm border-t border-b border-gray-200">
                <a
                  href="/Publication"
                  className="block px-4 py-2 text-gray-800 hover:bg-[#881337] hover:text-white"
                >
                  PUBLICATIONS
                </a>
                <a
                  href="/Readings"
                  className="block px-4 py-2 text-gray-800 hover:bg-[#881337] hover:text-white"
                >
                  READINGS
                </a>
              </div>
            )}

            <a
              href="/Departments"
              className="block px-4 py-2 text-gray-800 hover:bg-[#881337] hover:text-white"
            >
              DEPARTMENTS
            </a>
            <a
              href="/Results"
              className="block px-4 py-2 text-gray-800 hover:bg-[#881337] hover:text-white"
            >
              RESULTS
            </a>
            <a
              href="/Contact"
              className="block px-4 py-2 text-gray-800 hover:bg-[#881337] hover:text-white"
            >
              CONTACT US
            </a>
          </div>
        )}
      </nav>
    </div>
  );
};

export default Navbar;
