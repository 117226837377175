import React, { useEffect, useState } from "react";
import img from "../Institutions/assets/BANNER.jpg";
import img1 from "./assets/DUMMY.jpg";
import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { db } from "../Config/Config";
export default function Publication() {
  // ================================================ Data fetching ============================================= //
  const [showData, setShowData] = useState([]);
  const fetchData = async () => {
    const q = query(collection(db, "publications"), orderBy("order", "asc"));
    const querySnapshot = await getDocs(q);
    const data = [];
    querySnapshot.forEach((doc) => {
      data.push({
        id: doc.id,
        ...doc.data(),
      });
    });
    setShowData(data);
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div>
      <div
        className="w-full mx-auto px-9 py-8 bg-cover bg-center"
        style={{ backgroundImage: `url(${img})` }}
      >
        <div className="flex items-center text-black">
          <a
            href="/"
            className="flex font-semibold text-lg items-center text-gray-900 transition-colors duration-300"
          >
            <svg
              className="w-4 h-4 mr-2"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
            </svg>
            Home
          </a>
          <span className="mx-2">/</span>
          <h2 className="text-[#881337]">PUBLICATIONS</h2>
        </div>
      </div>

      <section>
        <div className="mx-auto w-full max-w-7xl px-8 md:px-10 py-6">
          <h2 class="text-xl px-6 py-6 font-medium md:text-2xl text-[#881337] md:leading-tight ">
            CMI PUBLICATIONS
          </h2>

          <div className="flex flex-col items-center">
            <div className="mb-6 grid gap-4 sm:grid-cols-2 sm:justify-items-stretch md:mb-10 md:grid-cols-3 lg:mb-12 lg:gap-6">
              {showData &&
                showData.map((data) => {
                  return (
                    <a
                      href="/"
                      className="flex flex-col gap-4 rounded-md border border-solid border-gray-300 px-4 py-2 md:p-0"
                    >
                      <img src={data.img_url?data.img_url:img1} alt="" className="h-60 object-cover" />
                      <div className="px-6 ">
                        <p className="mb-4 text-xl font-semibold">
                          {data.name}
                        </p>
                        <p className="mb-6 text-sm text-gray-600 sm:text-base lg:mb-8">
                          <span className="font-semibold text-gray-800">
                            Address:&nbsp;
                          </span>
                          <span className="text-gray-700">{data.address}</span>{" "}
                          <br />
                          <span className="font-semibold text-gray-800">
                            Phone:
                          </span>{" "}
                          <span className="text-gray-700">{data.phone}</span>{" "}
                          <br />
                          <span className="font-semibold text-gray-800 ">
                            Email:
                          </span>{" "}
                          <span className="text-blue-600">{data.email}</span>{" "}
                          <br />
                          <div className="mt-4">
                            <span className="font-semibold text-gray-800">
                              For Subscription Details:
                            </span>{" "}
                            <br />
                            <span className="text-[#881337]">1 Year:</span>{" "}
                            &nbsp;
                            <span className="text-gray-700">
                              ₹{data.oneyear}
                            </span>{" "}
                            <br />
                            <span className="text-[#881337]">
                              5 Years:
                            </span>{" "}
                            &nbsp;
                            <span className="text-gray-700">
                              ₹{data.fiveyear}
                            </span>{" "}
                            <br />
                            <span className="text-[#881337]">
                              20 Years:
                            </span>{" "}
                            &nbsp;
                            <span className="text-gray-700">
                              ₹{data.twentyyear}
                            </span>
                          </div>
                        </p>
                      </div>
                    </a>
                  );
                })}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
