import React, { useEffect, useState } from 'react'
import img from "./assets/BANNER.jpg";
import './css/Abot.css'
import { collection, getDocs, orderBy, query } from 'firebase/firestore';
import { db } from '../Config/Config';
export default function Dioceseoffice() {
  const [datalist,setdataList]=useState([])
  useEffect(()=>{
    const getData=async()=>{
      const data = [];
      const q = query(
        collection(db, "office-bearers"),
        orderBy("order", "asc")
      );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        data.push({ ...doc.data(), id: doc.id });
      });
      setdataList(data);
    }
    getData()
  },[])
  return (
    <div>
      <div
        className="w-full mx-auto px-9 py-8 bg-cover bg-center"
        style={{ backgroundImage: `url(${img})` }} // Set the local image as the background
      >
        <div className="flex items-center text-black">
          {/* Home SVG Icon */}
          <div className="flex font-semibold text-lg items-center text-gray-900  transition-colors duration-300">
            <svg
              className="w-4 h-4 mr-2"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
            </svg>
            Home
          </div>
          <span className="mx-2">/</span>
          <h2 className="text-[#881337]">Diocese Office Bearers</h2>
        </div>
      </div>

      <section class="text-gray-600 body-font">
        <h2 className="text-4xl  px-10 py-6 mt-8 font-semibold text-[#881337] ">
          Diocese Office Bearers
        </h2>
        <div class="container px-5 py-6 mx-auto">
          <div class="flex flex-wrap -m-4">
            {datalist.map((data) => {
              return (
                <div class="p-4 lg:w-1/2 md:w-1/2 w-full">
                  <div class="h-full flex flex-col items-center sm:flex-row sm:items-start sm:justify-start text-center sm:text-left transition-transform transform hover:-translate-y-2 duration-300 bg-white p-6 rounded-lg ">
                    <img
                      alt="team"
                      class="flex-shrink-0 rounded-lg w-48 h-48 object-cover object-center mb-4 sm:mb-0 shadow-lg transition-transform transform hover:scale-105 duration-300"
                      src={data.img_url===''?"https://greenacresportsmed.com.au/wp-content/uploads/2018/01/dummy-image.jpg":data.img_url}
                    />
                    <div class="flex-grow sm:pl-8">
                      <h2 class="title-font font-semibold text-2xl text-gray-900 transition-opacity duration-500 ease-in-out opacity-100 animate-fadeIn">
                        {data.designation}
                      </h2>
                      <h3 class="text-[#881337] mb-3 transition-opacity duration-500 ease-in-out opacity-100 animate-fadeIn delay-200">
                        {data.name}
                      </h3>
                      <p class="text-black mb-4 transition-opacity duration-500 ease-in-out opacity-100 animate-fadeIn delay-400">
                        <span class="font-semibold">Office:</span>{" "}
                        {data.address}
                        <br />
                        <span class="font-semibold">Email:</span>{" "}
                        <a
                          href="mailto:csimalabardiocese@gmail.com"
                          class="text-blue-500 hover:underline"
                        >
                          {data.email}
                        </a>
                        <br />
                        <span class="font-semibold">Ph:</span> {data.mobile}
                        <br />
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </div>
  );
}
