import React from "react";
import Navbar from "./components/Navbar";

import Footer from "./components/Footer";

import './App.css'
import { Routes, Route } from "react-router-dom";
import Home from "./components/Home/Home";
import Aboutdiocese from "./components/About/Aboutdiocese";
import History from "./components/About/History";
import Dioceseoffice from "./components/About/Dioceseoffice";
import Lightsofourdiocese from "./components/About/Lightsofourdiocese";
import Churches from "./components/Churches/Churches";
import Schools from "./components/Institutions/Schools";
import Collage from "./components/Institutions/Collage";
import Hospital from "./components/Institutions/Hospital";
import Retreatcenter from "./components/Institutions/Retreatcenter";
import Bokkshop from "./components/Institutions/Bokkshop";
import Homes from "./components/Institutions/Homes";
import Socialboards from "./components/Institutions/Socialboards";
import Publication from "./components/Publication/Publication";
import Readings from "./components/Publication/Readings";
import Departments from "./components/Publication/Departments";
import Contact from "./components/Contact/Contact";
import Results from "./components/Results/Results";
import Readmore from "./components/News/Readmore";
import Kaniv from "./components/About/Kaniv";
import Churchhistory from "./components/Churches/Churchhistory";
const App = () => {

  return (
    <>
        <div>
          <Navbar />
          <Routes>
            <Route index element={<Home />} />
            <Route path="/about-diocese" element={<Aboutdiocese />} />
            <Route path="/History" element={<History />} />
            <Route path="/Dioceseoffice" element={<Dioceseoffice />} />
            <Route
              path="/Lightsofourdiocese"
              element={<Lightsofourdiocese />}
            />
            <Route path="/Churches" element={<Churches />} />
            <Route path="/Schools" element={<Schools />} />
            <Route path="/Collage" element={<Collage />} />
            <Route path="/Hospital" element={<Hospital />} />
            <Route path="/Retreatcenter" element={<Retreatcenter />} />
            <Route path="/Bookshop" element={<Bokkshop />} />
            <Route path="/Homes" element={<Homes />} />
            <Route path="/Socialboards" element={<Socialboards />} />
            <Route path="/Publication" element={<Publication />} />
            <Route path="/Readings" element={<Readings />} />
            <Route path="/Departments" element={<Departments />} />
            <Route path="/Contact" element={<Contact />} />
            <Route path="/Results" element={<Results />} />
            <Route path="/Readmore" element={<Readmore />} />
            <Route path="/kaniv" element={<Kaniv />} />
            <Route path="/church-history" element={<Churchhistory />} />
          </Routes>
          <Footer />
        </div>
    </>
  );
};

export default App;
