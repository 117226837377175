import React, { useEffect, useState } from "react";
import img from "./assets/BANNER.jpg"; 
import lo from "./assets/logo.png";
import church1 from "./assets/church.jpg";
import church2 from "./assets/church 2.jpg";
import { collection, doc, getDoc, getDocs, orderBy, query } from "firebase/firestore";
import { db } from "../Config/Config";

export default function Aboutdiocese() {
  // ================================================= Conmmittee Data ============================================== //
  const [committee, setCommittee] = useState([]);
  const fetchCommittee = async () => {
    const datas = [];
    const q = query(collection(db, "committee-synod"), orderBy("order", "asc"));
    const querySnapshot = await getDocs(q);
    querySnapshot.docs.map((doc) => datas.push({ ...doc.data(), id: doc.id }));
    setCommittee(datas);
  };

  // =================================================  Officers Data ============================================== //
  const [officersList, setofficersList] = useState([]);
  const fetchofficersList = async () => {
    const datas = [];
    const q = query(
      collection(db, "officers-diocese"),
      orderBy("order", "asc")
    )
    const querySnapshot = await getDocs(q);
    querySnapshot.docs.map((doc) => datas.push({ ...doc.data(), id: doc.id }));
    setofficersList(datas);
  };

  // ================================================= Leaders Data ============================================== //
  const [leaders, setleaders] = useState([]);
  const fetchleaders = async () => {
    const datas = [];
    const q = query(collection(db, "team-leaders"), orderBy("order", "asc"));
    const querySnapshot = await getDocs(q);
    querySnapshot.docs.map((doc) => datas.push({ ...doc.data(), id: doc.id }));
    setleaders(datas);
  };
  // ================================================= Bishop Data ============================================== //
  const [Bishop, setBishop] = useState({});
  const fetchBishop = async () => {
    const docSnap = await getDoc(doc(db, "bishop", "bishop"));
    if(docSnap.exists){
      setBishop(docSnap.data())
    }
  };

  useEffect(() => {
    fetchBishop();
    fetchofficersList();
    fetchCommittee();
    fetchleaders();
  }, []);
  return (
    <div>
      <div
        className="w-full  px-9 py-8 bg-cover bg-center"
        style={{ backgroundImage: `url(${img})` }} // Set the local image as the background
      >
        <div className="flex items-center text-black">
          {/* Home SVG Icon */}
          <a
            href="/"
            className="flex font-semibold text-lg items-center text-gray-900 transition-colors duration-300"
          >
            <svg
              className="w-4 h-4 mr-2"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
            </svg>
            Home
          </a>
          <span className="mx-2">/</span>
          <h2 className="text-[#881337]">ABOUT DIOCESE</h2>
        </div>
      </div>

      <div className="bg-white   bg-opacity-80 p-6 rounded-lg shadow-lg">
        <h1 className="text-4xl  text-center py-12 font-bold text-[#881337] ">
          About CSI Malabar Diocese
        </h1>
        <p className="text-gray-800 mt-6 px-6  font-serif italic text-lg text-center border-l-4 border-gray-500 pl-2">
          “And we know that all things work together for good to those who love
          God, to those who are called according to His purpose”
          <span className="block mt-2 px-6 text-right font-bold text-gray-700">
            [Romans 8:28]
          </span>
        </p>

        <section className="mb-6">
          <h2 className="text-3xl px-6 font-semibold text-[#881337] mt-8">
            Diocese of Malabar
          </h2>
          <p className="text-gray-700 px-6 mt-4">
            Malabar Diocese is comprising the area within Kerala State in the
            north,spread in five revenue districts such as
            Palakkad,Malappuram,Kozhikode,Wayanad and Kannur consisting of 85
            churches including the out stations. There are 46 Presbyters and 36
            Upadesis and Church Workers engaged in the ecclesiastical services
            within the Diocese.
          </p>
          <p className="text-gray-700 px-6 mt-4">
            We are doing the Ministry by serving the society through following
            Diocesan institutions. 51 schools,2 colleges, 1 Technical School, 1
            Hospital,Homes for financially backward children,Working Women's
            hostel, etc.We thank God that our educational institutions are
            always in the forefront maintaining standard of education par
            excellence with other schools. Our Mission Hospital in Codacal
            ;specially the newly commissioned physical rehabilitation centre is
            doing an effective healing ministry. Our Pre-Marital counseling
            program is functioning very systematically in our Retreat Centre in
            Kozhikode.
          </p>
          <p className="text-gray-700 px-6 mt-4">
            Bifurcation of North Kerala Diocese into Dioceses of Malabar and
            Cochin officially came into effect on 9th April 2015 during the
            Special Synod meeting held in Chennai.The Official declaration of
            the formation of the Diocese of Malabar was made by the Moderator of
            the Church of South India the Most Rev. Dr. G. Dyvasirvadam in a
            grand Thanks giving service held at CSI Cathedral,Kozhikode on the
            11th of October 2015.Subsequently,the Diocese of Malabar started
            functioning in the Head Office in theCSI Diocesan Office, Bank Road,
            Kozhikode,under the Chairmanship of the Moderator's Commissary
            Rt.Rev.B.N.Fenn and Administrative Committee appointed by the Synod.
            The following members served in the Administrative Committee:
          </p>
          <p className="text-gray-700 px-6 mt-4">
            The Diocese of Malabar started functioning from the CSI Diocesan
            Office, Bank Road, Kozhikode, under the Chairmanship of the
            Moderator's Commissary Rt. Rev. B.N. Fenn and an Administrative
            Committee appointed by the Synod :
          </p>
        </section>
        <div className="grid grid-cols-1 px-6 md:grid-cols-1 lg:grid-cols-1 ">
          {committee.map((doc) => (
            <div
              key={doc.id}
              className="flex items-center p-2 bg-white "
            >
              <span className="mr-2 text-gray-700">→</span>
              <p className="text-gray-800">{doc.name}</p>
            </div>
          ))}
        </div>
        <section className="mb-6">
          <h2 className="text-xl px-6 font-semibold text-[#881337] mt-8">
            Diocese of Malabar
          </h2>
          <p className="text-gray-700 px-6 mt-4">
            The Administrative Committee functioned until the election and
            formation of the Executive Committee and Office Bearers for Malabar
            Diocese in the Diocesan Council meeting held on 24th September 2015.
          </p>
          <p className="text-gray-700 px-6 mt-4">
            The first meeting of the Diocesan Council was held at the CSI
            Cathedral, Kozhikode on 14th July 2015. The Council adopted new
            constitution for the functioning of the Diocese. The Moderator's
            Commissary the Rt. Rev. B. N. Fenn chaired the meeting.
          </p>
          <p className="text-gray-700 px-6 mt-4">
            The second meeting of the Diocesan Council was held at the CSI St.
            Mary's English Church, Kozhikode on 24th September 2015. The
            Moderator the Most Rev. Dr. G. Dyvasirvadam presided over the
            meeting. The Moderator's Commissary was also present in the meeting.
            The Council elected new officers and Executive Committee and
            representatives to the Synod. The following were elected as the
            Officers of the Diocese:-
          </p>
          <div className="grid grid-cols-1 px-6 md:grid-cols-1 lg:grid-cols-1 ">
            {officersList.map((person) => (
              <div
                key={person.id}
                className="relative p-6  "
              >
                <div className="absolute  top-0 left-0 w-1.5 h-full "></div>
                <p className="text-xl font-bold text-gray-900 mb-2 flex items-center">
                 
                  {person.name}
                </p>
                <p className="text-base text-gray-700">{person.position}</p>
              </div>
            ))}
          </div>
          <p className="text-gray-700 px-6 mt-4">
            We thank God for giving us the following group of dynamic team
            leaders to head our ministry.
          </p>

          <div className="grid grid-cols-1 px-6 mt-8 md:grid-cols-1 lg:grid-cols-1 ">
            {leaders.map((person) => (
              <div
                key={person.id}
                className="flex flex-col p-2 bg-white "
              >
                <div className="flex items-center mb-1">
                  <span className="mr-2 text-[#0f0f0f]">→</span>
                  <p className="text-gray-900 font-medium">{person.name}</p>
                </div>
                {person.subtitle && (
                  <p className="text-gray-600 ml-6 text-sm">
                    {person.position}
                  </p>
                )}
              </div>
            ))}
          </div>
          <h1 className="text-2xl mt-6 px-6 font-bold text-[#881337] ">
            Bicentenary celebration of Basel Mission at Calicut
          </h1>

          <p className="text-gray-700 px-6 mt-4">
            The people of Malabar Diocese had a very memorable event of the
            Grand finale of the bicentenary celebration of Basel Mission at
            Calicut on October 11th 2015.The spectacular historic rally through
            the streets of Calicut city will never fade away from the minds the
            young and old. Our Moderator Most Rev.Dr.G.Dyvasirvadam, Officers of
            the Synod, Bishops, Leaders of the secular society and hundreds of
            Church members tookpart in the jubilant occasion.
          </p>
          <p className="text-gray-700 px-6 mt-4">
            Another mile stone in the history of Malabar Diocese is the election
            of the panel to select the first Shepherd to our Diocese on February
            16th 2016. By the Grace of God almighty Rt.Rev.Dr.Royce Manoj
            Victorwas consecrated as the first bishop of the Diocese of Malabar
            on 17th May 2016 in a service held at the CSI Cathedral, Kozhikode.
          </p>

          <p className="text-gray-700 px-6 mt-4">
            The First Executive committee met on 20th May 2016 under the
            chairmanship of the new Bishop of the Diocese.In this meeting the
            Bishop stressed upon to give more emphasis on furtherextending
            missionary works which is our prime responsibility. Hence, the
            Diocese is now focusing on self financing projects by each parish
            thus to complement the income of the Diocese.Our dream is to acquire
            our own mission field in the near future.The 19 sub-committees under
            the executive committee are functioning verywell.Our Diocesan
            magazine "CSI Malabar Sabhamithram"is playing a vital role in
            efficient communication media. Very soon we will have own website
            and with various links which will be useful to all our members.
          </p>

          <p className="text-gray-700 px-6 mt-4">
            The Diocese has taken keen interest in enriching Sunday school,Youth
            fellowship and women's fellowship through introducing various
            projects and educative sessions.Our ecological concern department is
            vigorously making awareness drive concentrating in all our parishes
            and in the neighborhood.We are targeting for declaring all our 43
            parishes as "Green Parishes" by the year 2018.
          </p>

          <p className="text-gray-700 px-6 mt-4">
            Most of our Basel Mission built Churches iscelebrating 150thto 175th
            yearanniversaries. We are in the move to renovate /re build these
            churches in the coming years and so also the parsonages.
          </p>

          <p className="text-gray-700 px-6 mt-4">
            At present we are in the process of building our own Bishop's House
            in our office premises.
          </p>

          <p className="text-gray-700 px-6 mt-4">
            It is really amazing to see how God is leading ahead the new Diocese
            of Malabar, in the midst of various crises and confusions.
          </p>

          <p className="text-gray-800 mt-8 px-6 py-6  font-serif italic text-lg text-center border-l-4 border-gray-500 pl-2">
            "And my God shall supply all your need according to his riches in
            glory by Christ Jesus"
            <span className="block mt-2 text-right font-bold text-gray-700">
              [Philippians 4:16:19]
            </span>
          </p>
        </section>

        <div className="grid grid-cols-1 px-6 md:grid-cols-2 gap-16 rounded-xl bg-gray-50 mt-12 py-12">
          <div className="flex flex-col items-center justify-center">
            <h1 className="text-4xl font-bold text-[#881337] mt-4">
              OUR SHEPHERD
            </h1>
            <div className="w-56 h-56 rounded-full overflow-hidden flex items-center justify-center mt-8">
              <img src={Bishop.img_url} alt="Logo" className="w-56 h-56 object-contain" />
            </div>
            <h1 className="text-xl mt-4 font-bold text-[#881337]">
              {Bishop.name}
            </h1>
          </div>

          <div className="flex flex-col items-center justify-center">
            <h1 className="text-4xl font-bold text-[#881337] mb-4">OUR LOGO</h1>
            <img src={lo} alt="Logo" className="w-56 h-56 mt-8" />
          </div>
        </div>

        <section className="mt-12">
          <h1 class="text-4xl px-6 font-bold text text-[#881337] ">
            Theme of the Logo
          </h1>

          <div class="flex flex-col px-6  items-center justify-center ">
            <div class="space-y-8 py-8">
              <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                <div class="flex flex-col p-6 bg-white border border-gray-300 rounded-lg  hover:shadow-xl transition-shadow duration-300">
                  <h2 class="text-xl font-semibold text-gray-900 mb-4">
                    {" "}
                    The School & Chimney:
                  </h2>
                  <p class="text-gray-700">
                    The school and the chimney remind us of the educational
                    institutions and industries established by the missionaries.
                    They also signify the Church's need to remain vibrant and
                    engaged in society.
                  </p>
                </div>
                <div class="flex flex-col p-6 bg-white border border-gray-300 rounded-lg  hover:shadow-xl transition-shadow duration-300">
                  <h2 class="text-xl font-semibold text-gray-900 mb-4">
                    The Bible:
                  </h2>
                  <p class="text-gray-700">
                    The Bible is the foundation of the Diocese and its members.
                    It represents the importance of daily reading, studying, and
                    meditating on it to nurture our Christian lives.
                  </p>
                </div>
                <div class="flex flex-col p-6 bg-white border border-gray-300 rounded-lg  hover:shadow-xl transition-shadow duration-300">
                  <h2 class="text-xl font-semibold text-gray-900 mb-4">
                    The Sail-Borne Ship:
                  </h2>
                  <p class="text-gray-700">
                    The ship symbolizes the missionaries who came to the Malabar
                    Coast to evangelize. It also represents our journey through
                    life based on faith, towards the fulfillment of God's
                    Kingdom.
                  </p>
                </div>
                <div class="flex flex-col p-6 bg-white border border-gray-300 rounded-lg  hover:shadow-xl transition-shadow duration-300">
                  <h2 class="text-xl font-semibold text-gray-900 mb-4">
                    The Coconut Tree:
                  </h2>
                  <p class="text-gray-700">
                    The coconut tree signifies the Diocese’s commitment to
                    environmental concerns. Its green color represents growth
                    and vitality.
                  </p>
                </div>
                <div class="flex flex-col p-6 bg-white border border-gray-300 rounded-lg  hover:shadow-xl transition-shadow duration-300">
                  <h2 class="text-xl font-semibold text-gray-900 mb-4">
                    The Stole:
                  </h2>
                  <p class="text-gray-700">
                    The sail of the ship, shaped like a stole, symbolizes the
                    legacy of the locally accepted historical episcopacy of the
                    Church of South India.
                  </p>
                </div>
                <div class="flex flex-col p-6 bg-white border border-gray-300 rounded-lg  hover:shadow-xl transition-shadow duration-300">
                  <h2 class="text-xl font-semibold text-gray-900 mb-4">
                    The Cross:
                  </h2>
                  <p class="text-gray-700">
                    The cross is a symbol of love, sacrifice, and victory. It
                    reflects the breadth, length, height, and depth of God's
                    love (Eph. 3:18) and encourages us to be filled with the
                    divine love of the Cross.
                  </p>
                </div>
                <div class="flex flex-col p-6 bg-white border border-gray-300 rounded-lg  hover:shadow-xl transition-shadow duration-300">
                  <h2 class="text-xl font-semibold text-gray-900 mb-4">
                    The Mountain & The Sea:
                  </h2>
                  <p class="text-gray-700">
                    The mountain and sea in the emblem depict the landscapes of
                    the Malabar Diocese, particularly the mountains of Wayanad
                    and the coastal area. They embody the all-encompassing love
                    of God.
                  </p>
                </div>
                <div class="flex flex-col p-6 bg-white border border-gray-300 rounded-lg  hover:shadow-xl transition-shadow duration-300">
                  <h2 class="text-xl font-semibold text-gray-900 mb-4">
                    The Candle:
                  </h2>
                  <p class="text-gray-700">
                    Each member of the Malabar Diocese is encouraged to be like
                    a candle burning brightly for Jesus Christ. Just as a candle
                    provides light to others while melting away, we are called
                    to sacrifice ourselves for building the Kingdom of Heaven.
                    We should be service-minded and willing to contribute
                    selflessly.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <h2 className="mt-10 px-4  text-4xl font-medium text-[#881337] sm:text-3xl">
            About OUR CATHEDRAL (History of CSI Cathedral Calicut)
          </h2>
          <div className=" px-4 flex flex-col py-10 lg:flex-row lg:items-center lg:max-w-screen-7xl">
            <div className="lg:w-2/3 lg:pr-10">
              <p className="text-gray-700 mt-4">
                The Church of South India (CSI) Cathedral is a landmark of
                Kozhikode City and a symbol of the Christian presence and
                services contributedby the German missionaries of the Basel
                Mission in India.
              </p>
              <p className="text-gray-700 mt-4">
                The Congregation of Calicut was established on May 15th 1842,
                the Pentecost Sundaywith a holy communion service by a German
                Mission Rev. John Michel Fritz
              </p>

              <p className="text-gray-700 mt-4">
                Located on the eastern side of the Mananchira Square, it is the
                biggest Basel Mission church in the Malabar region, built eight
                years after the missionaries came to India. The architecture is
                a combination of European and Kerala styles. On top of the
                arched entrance porch, there is a three-tiered turret. The
                church has a main hall and a cluster of rooms to the back and
                the sides. The timber roof of the church is an imposing
                structure. The extensive use of wood in this structure indicates
                the abundance of availability of hard- wood. The entire building
                was roofed with tiles and flooring done with clay flooring
                tiles, both were the products of Commonwealth Tile factory
                founded by BEM.
              </p>
            </div>

            <div className="lg:w-1/3 lg:pl-10 mt-8 lg:mt-0">
              <div className="flex justify-center lg:justify-end">
                <img
                  className="h-72 w-auto rounded-xl object-contain shadow-lg"
                  src={church1}
                  alt="About Us"
                />
              </div>
            </div>
          </div>
          <p className="text-gray-700 px-4 mt-4">
            The dedication of the Church was held on December 20th 1856. The
            number of congregation was increased up to 2880 in 1914. The then
            Basel Mission leaders met at the church in 1916 July 3rd and
            resolved to join with the ecumenical movement and in April 1st 1919
            the Church joined in South Indian United Church. And later in
            September 27th 1947, when SIUC joined with other partner Churches
            and formed the Church of South India (CSI), this Church became a
            part of the CSI North Kerala Diocese and elevated as the Cathedral
            of the Diocese. When the North Kerala Diocese is bifurcated and
            formed the new Diocese of Malabar, the CSI Cathedral became the
            Cathedral of the Diocese of Malabar.
          </p>

          <p className="text-gray-700 px-4 mt-4">
            The Installation of the First Bishop Rt. Rev. T. G Stuart Smith of
            North Kerala was held at the Cathedral on October 12, 1947. The
            Second Bishop Rt. Rev. Richard Lipp was Consecrated and Insatalled
            at the Cathedral in 1960. The third Bishop (first Indian Bishop) Rt.
            Rev. T. B. Benjamin was consecrated at the Cathedral in 1960,
            followed by the consecration service of the Rt. Rev. Dr. K. C. Seth
            was held in 1977. The fifth Bishop the Rt. Rev. P.G. Kuruvilla was
            consecrated in 1990. Followed by Bishop Rt. Rev. George Isaac was
            consecrated in 2000. Then the Rt. Rev. Dr. K. P. Kuruvila became
            Bishop in 2006. Eight and last bishop of North Kerala the Rt. Rev.
            B. N. Fenn was consecrated at the CSI Cathdral in 2013. After
            Bifurcation the first Bishop in the Diocese of Malabar was
            consecrated on May 17, 2016, the Rt. Rev. Dr. Royce ManojVicator.
          </p>

          <div className=" flex flex-col px-4 py-10 lg:flex-row lg:items-center lg:max-w-screen-7xl">
            <div className="lg:w-2/3 lg:pr-10">
              <p className="text-gray-700  mt-4">
                The CSI Cathedral stands as a landmark of 175 years worship and
                fellowship in this historical city of Calicut.
              </p>
              <p className="text-gray-700 mt-4">
                The Cathedral is the largest church in the Malabar region with
                membership over 8000 though the, active congregation is
                approximately 2000. We follow both CSI and the Basel Mission
                liturgies for worship. The seating capacity of the main hall
                is680.
              </p>

              <p className="text-gray-700 mt-4">
                The church acquired a pipe organ which was a gift from the St.
                Ayden's Church in Cheltenham, England. The church choir is one
                of the most important institutions functioning within the parish
                .The choirs down the years have been a blend of elders and
                junior choir is the stepping stone to the regular choir.
                TheCathedral has reputation for choral singing. We have 30
                member choirs. The crowning glory of the cathedrais the carols
                of Christmas.The carol service is the acme of choral singing.
              </p>
            </div>

            <div className="lg:w-1/3 lg:pl-10 mt-8 lg:mt-0">
              <div className="flex justify-center lg:justify-end">
                <img
                  className="h-72 w-auto rounded-xl object-contain shadow-lg"
                  src={church2}
                  alt="About Us"
                />
              </div>
            </div>
          </div>
          <p className="text-gray-700 px-4 mt-4">
            Along with Chairman there are two full time ministers, one retired
            associate Presbyters and two sextons are in service. A twelve member
            pastorate committee is actively managing the administration of the
            Cathedral, widow's accommodation, and community hall.
          </p>

          <p className="text-gray-700 px-4 mt-4">
            Cathedral has one outstation at Bilathikulam, A prayer Centre at
            Peruvayal and a Mission Centre at Kattangal.
          </p>
          <p className="text-gray-700 px-4 mt-4">
            At present Rev. Dr. T. I James the Chairman (9447576406), Rev.
            Bennet and Rev. Jijo Albert are serving as the Assistant and the
            service or Rev Dr. Vincent Moses is available for the Pastoral care
            ministry of the Diocese.
          </p>
        </section>
      </div>
    </div>
  );
}
