import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';

export default function Churchhistory() {
  const location = useLocation();
  const data = location.state;
  const [loading,setLoading]=useState(true);
  useEffect(() => {
    if(data){
      setLoading(false);
    }
  },[data])
  return (
    <div>
      {loading ? (
        <section>
          <div className="flex flex-col items-center justify-center px-5 md:px-10">
            <div className="mx-auto w-full max-w-5xl py-12 md:py-12 lg:py-12">
              <p className="mb-5 text-2xl text-[#881337] font-extrabold uppercase">
                {" "}
                Content Not Available
              </p>
              <div className="mb-6 min-h-[1px] min-w-full bg-[#e2e2e2]"></div>
            </div>
          </div>
        </section>
      ) : (
        <section>
          <div className="flex flex-col items-center justify-center px-5 md:px-10">
            <div className="mx-auto w-full max-w-5xl py-12 md:py-12 lg:py-12">
              <p className="mb-5 text-2xl text-[#881337] font-extrabold uppercase">
                {" "}
                {data.churchName}
              </p>
              <div className="mb-6 min-h-[1px] min-w-full bg-[#e2e2e2]"></div>

              <div className="mb-6 flex flex-col items-start gap-y-3">
                <p className="text-sm font-bold uppercase sm:text-base">
                  {data.address}
                </p>
                <p className="text-sm sm:text-base">{data.history}</p>
              </div>
            </div>
          </div>
        </section>
      )}
    </div>
  );
}
