import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone, faMapMarkerAlt, faClock } from '@fortawesome/free-solid-svg-icons';
import { addDoc, collection, getDocs } from "firebase/firestore"; 
import { db } from '../Config/Config';

export default function Contact() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [order, setOrder] = useState(0);
  // ========================================================== Order fetching =========================================================== //
  const fetchOrder = async () => {
    const querySnapshot = await getDocs(collection(db, "contact-us"));
    setOrder(querySnapshot.size);
  };
  useEffect(() => {
    fetchOrder();
  }, []);
  // ========================================================== Input handling =========================================================== //

  const handleTextChange = (e) => {
    setName(e.target.value);
  };

  const handleTextEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleTextMessage = (e) => {
    setMessage(e.target.value);
  };
  // ========================================================== Upload data =========================================================== //

  const handleUpload = async (e) => {
    e.preventDefault();

    try {
      await addDoc(collection(db, "contact-us"), {
        Name: name,
        Email: email,
        Message: message,
        order: order,
      });
      setName("");
      setEmail("");
      setMessage("");
      fetchOrder();
      alert("Message sent successfully!");
    } catch (error) {
      console.error("Error sending message: ", error);
      alert("Failed to send message. Please try again.");
    }
  };

  return (
    <div
      id="contact-us"
      className="overflow-hidden py-6 px-4 sm:px-6 lg:px-8 bg-gradient-to-r from-gray-50 to-gray-100"
    >
      <div className="relative mx-auto max-w-6xl">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-16">
          {/* Contact Information */}
          <div className="flex flex-col justify-center">
            <h2 className="text-3xl font-medium tracking-tight text-[#171717] sm:text-3xl">
              Contact Information
            </h2>
            <p className="mt-6 text-lg leading-7 text-gray-600">
              Feel free to reach out to us through the following contact
              details.
            </p>
            <ul className="mt-8 space-y-6">
              <li className="flex items-start text-lg text-gray-700">
                <FontAwesomeIcon
                  icon={faMapMarkerAlt}
                  className="h-6 w-6 text-[#881337] mr-4"
                />
                <span>
                  <strong>Address:</strong> CSI Diocesan Office, Bank Road,
                  Calicut - 673 001
                </span>
              </li>
              <li className="flex items-start text-lg text-gray-700">
                <FontAwesomeIcon
                  icon={faPhone}
                  className="h-6 w-6 text-[#881337] mr-4"
                />
                <span>
                  <strong>Phone:</strong> 0495 - 2721748, 2722748, 2723748
                </span>
              </li>
              <li className="flex items-start text-lg text-gray-700">
                <FontAwesomeIcon
                  icon={faEnvelope}
                  className="h-6 w-6 text-[#881337] mr-4"
                />
                <span>
                  <strong>Email:</strong> csimalabardiocese@gmail.com
                </span>
              </li>
              <li className="flex items-start text-lg text-gray-700">
                <FontAwesomeIcon
                  icon={faClock}
                  className="h-6 w-6 text-[#881337] mr-4"
                />
                <span>
                  <strong>Office Hours:</strong> Mon-Fri 9am-5pm
                </span>
              </li>
            </ul>
          </div>

          <div className="bg-white p-8 rounded-lg shadow-lg">
            <form
              className="mt-8 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
              onSubmit={handleUpload}
            >
              <div className="sm:col-span-2">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-gray-700"
                >
                  Name
                </label>
                <div className="mt-1">
                  <input
                    value={name}
                    onChange={handleTextChange}
                    name="name"
                    type="text"
                    id="name"
                    required
                    className="border border-[#881337] block w-full rounded-md py-3 px-4 shadow-sm focus:border-[#881337] focus:ring-[#881337]"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email
                </label>
                <div className="mt-1">
                  <input
                    value={email}
                    onChange={handleTextEmail}
                    name="email"
                    id="email"
                    type="email"
                    required
                    className="border border-[#881337] block w-full rounded-md py-3 px-4 shadow-sm focus:border-[#881337] focus:ring-[#881337]"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label
                  htmlFor="message"
                  className="block text-sm font-medium text-gray-700"
                >
                  Message
                </label>
                <div className="mt-1">
                  <textarea
                    value={message}
                    onChange={handleTextMessage}
                    name="message"
                    id="message"
                    rows="4"
                    required
                    className="border border-[#881337] block w-full rounded-md py-3 px-4 shadow-sm focus:border-[#881337] focus:ring-[#881337]"
                  ></textarea>
                </div>
              </div>
              <div className="flex justify-end sm:col-span-2">
                <button
                  type="submit"
                  className="inline-flex items-center rounded-md px-6 py-3 font-medium focus:outline-none focus-visible:ring focus-visible:ring-[#881337] shadow-lg sm:text-sm transition-transform duration-150 text-white bg-[#881337] hover:bg-[#700e26] transform hover:scale-105"
                >
                  <span>Send Message</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
