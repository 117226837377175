import React from 'react';
import img from './assets/BANNER.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGraduationCap, faSchool, faChalkboardTeacher, faChild } from '@fortawesome/free-solid-svg-icons';

export default function Schools() {
  return (
    <div>
      <div
        className="w-full mx-auto px-9 py-8 bg-cover bg-center"
        style={{ backgroundImage: `url(${img})` }}
      >
        <div className="flex items-center text-black">
          <a
            href="/"
            className="flex font-semibold text-lg items-center text-gray-900  transition-colors duration-300"
          >
            <svg
              className="w-4 h-4 mr-2"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
            </svg>
            Home
          </a>
          <span className="mx-2">/</span>
          <h2 className="text-[#881337]">Schools</h2>
        </div>
      </div>

      <section className="bg-gray-50 py-16">
        {/* Container */}
        <div className="mx-auto w-full max-w-7xl px-5 md:px-10">
          {/* Title */}
          <div className="mb-12 flex flex-col md:flex-row items-center justify-between">
            <h2 className="mb-8 text-3xl font-normal text-[#881337] md:mb-0 lg:mb-16">
              CSI Schools
            </h2>
            <p className="text-base text-gray-900 max-w-lg mb-6">
              Discover the extensive network of educational institutions within the CSI Malabar Diocese. From esteemed higher secondary schools to foundational lower primary schools.
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {/* Higher Secondary Schools */}
            <div className="bg-white rounded-lg p-6 flex flex-col items-center text-center shadow-lg transform hover:scale-105 transition-transform">
              <FontAwesomeIcon icon={faGraduationCap} className="w-14 h-14 text-[#881337] mb-4" />
              <h3 className="text-xl font-semibold text-gray-800">Higher Secondary Schools</h3>
            </div>

            {/* High Schools */}
            <div className="bg-white rounded-lg p-6 flex flex-col items-center text-center shadow-lg transform hover:scale-105 transition-transform">
              <FontAwesomeIcon icon={faSchool} className="w-14 h-14 text-[#881337] mb-4" />
              <h3 className="text-xl font-semibold text-gray-800">High Schools</h3>
            </div>

            {/* Upper Primary Schools */}
            <div className="bg-white rounded-lg p-6 flex flex-col items-center text-center shadow-lg transform hover:scale-105 transition-transform">
              <FontAwesomeIcon icon={faChalkboardTeacher} className="w-14 h-14 text-[#881337] mb-4" />
              <h3 className="text-xl font-semibold text-gray-800">Upper Primary Schools</h3>
            </div>

            {/* Lower Primary Schools */}
            <div className="bg-white rounded-lg p-6 flex flex-col items-center text-center shadow-lg transform hover:scale-105 transition-transform">
              <FontAwesomeIcon icon={faChild} className="w-14 h-14 text-[#881337] mb-4" />
              <h3 className="text-xl font-semibold text-gray-800">Lower Primary Schools</h3>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
