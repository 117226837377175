/* eslint-disable jsx-a11y/iframe-has-title */
import { useRef, useEffect, useState } from 'react';
import img from './assets/MAP.png';
import { useSpring, animated } from '@react-spring/web';
import ll from './assets/ll.jpg'
import kani1 from './assets/kani2.jpg'
import './css/Abot.css'
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../Config/Config';

// import pdf from './'
const About = () => {
  // Define the animation for the text
  const textAnimation = useSpring({
    from: { opacity: 0, transform: 'translateY(20px)' },
    to: { opacity: 1, transform: 'translateY(0)' },
    config: { duration: 800 },
  });

  // Define the animation for the image
  const imageAnimation = useSpring({
    from: { opacity: 0, transform: 'translateX(20px)' },
    to: { opacity: 1, transform: 'translateX(0)' },
    config: { duration: 800 },
    delay: 200, // Delay the image animation slightly
  });
  useEffect(() => {
    // Load the Facebook SDK for JavaScript
    const script = document.createElement("script");
    script.async = true;
    script.defer = true;
    script.crossOrigin = "anonymous";
    script.src = "https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v15.0";
    document.body.appendChild(script);
  }, []);

  const videoRefs = useRef([]);

  useEffect(() => {
    videoRefs.current.forEach((videoRef) => {
      videoRef.addEventListener('play', handlePlay);
    });

    // return () => {
    //   videoRefs.current.forEach((videoRef) => {
    //     videoRef.removeEventListener('play', handlePlay);
    //   });
    // };
  }, []);

  const handlePlay = (event) => {
    videoRefs.current.forEach((videoRef) => {
      if (videoRef !== event.target) {
        videoRef.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
      }
    });
  };
  const [videos,setvideos] = useState([])
  const fetchVideo = async()=>{
    var videosList = [];
    const ref = collection(db,"fb-video");
    const querySnapshot =  await getDocs(ref);
     querySnapshot.forEach((doc)=>{
        // console.log(doc.data())
        videosList.push(doc.data().link)
     });
     setvideos(videosList)
  }
  useEffect(()=>{
    fetchVideo();
  },[])




  return (
    <div className="bg-white">
      <div className="fixed bottom-6 right-6 z-50">
        <a
          href="https://chat.whatsapp.com/Fl7LDV1QTmKBvrIz5UL0nF" // Replace with your WhatsApp group link
          target="_blank"
          rel="noopener noreferrer"
          className="bg-gradient-to-r border border-[#6e6e6e] from-[#ffffff] to-[#ffffff] p-3 rounded-full shadow-xl transform hover:scale-110 transition-transform duration-300 ease-in-out flex items-center justify-center space-x-2"
        >
          <img
            src="https://img.icons8.com/color/48/whatsapp--v1.png" // Replace with the path to your WhatsApp logo
            alt="WhatsApp"
            className="w-8 h-8"
          />
        </a>
      </div>

      {/* <VideoPlayer/> */}

      <div className="mx-auto flex flex-col px-8 py-10 lg:flex-row lg:items-center lg:max-w-screen-xl">
        {/* Text Section */}
        <animated.div style={textAnimation} className="lg:w-2/3 lg:pr-10">
          <h2 className="mb-7 text-3xl font-medium text-[#881337] sm:text-4xl">
            About CSI Malabar Diocese
          </h2>
          <blockquote className="relative p-4 text-lg text-gray-700 border-l-4 border-[#881337] bg-gray-100 rounded-md shadow-sm">
            <p className="italic text-[#881337]">
              "And we know that all things work together for good to those who
              love God, to those who are the called according to His purpose"
            </p>
            <footer className="mt-2 text-right text-sm text-gray-600">
              <cite>[Rom 8:28]</cite>
            </footer>
          </blockquote>
          <p className="mt-6 mb-6 text-lg leading-relaxed text-gray-600 sm:leading-loose">
            Malabar Diocese is comprising the area within Kerala State in the
            north, spread in five revenue districts such as Palakkad,
            Malappuram, Kozhikode, Wayanad, and Kannur consisting of 85 churches
            including the out stations. There are 46 Presbyters and 36 Upadesis
            and Church Workers engaged in the ecclesiastical services within the
            Diocese.
          </p>
          <a
            href="/about-diocese"
            className="border-b-4 border-[#881337] pb-2 text-lg font-bold text-[#881337] hover:text-gray-800 transition duration-300 ease-in-out"
          >
            Read More
          </a>
        </animated.div>

        {/* Image Section */}
        <animated.div style={imageAnimation} className="lg:w-1/3 lg:pl-10">
          <div className="mt-4 flex justify-end">
            <img
              className="h-72 w-auto rounded-xl object-contain  shadow-lg"
              src={img}
              alt="About Us"
            />
          </div>
        </animated.div>
      </div>

      <div className="mx-auto flex flex-col px-8 py-10 lg:flex-row lg:items-center lg:max-w-screen-xl">
        <div className="w-full overflow-x-auto scrollbar-hide">
          <div className="flex space-x-4 sm:space-x-8 pb-4">
            {videos.map((videoSrc, index) => (
              <div
                className="min-w-[300px] sm:min-w-[560px] flex-shrink-0"
                key={index}
              >
                <div className="aspect-video w-full">
                  <iframe
                    className="rounded-xl w-full h-full"
                    ref={(el) => (videoRefs.current[index] = el)}
                    src={videoSrc}
                    style={{ border: "none", overflow: "hidden" }}
                    scrolling="no"
                    frameBorder="0"
                    allowFullScreen={true}
                    allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                  >
                  </iframe>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div class="relative mx-auto lg:max-w-screen-xl bg-black bg-opacity-40 bg-cover bg-center md:max-w-screen-lg">
        <img
          class="absolute h-full w-full object-cover"
          src={kani1}
          alt="Background"
        />
        <div class="relative text-white lg:w-1/2">
          <div class=" bg-opacity-30 p-6 lg:p-12   backdrop-blur-md">
            <p class="py-6 font-serif font-light"></p>
            <h2 class="font-serif text-4xl text-gray-600 font-bold leading-tight lg:text-5xl">
              കനിവ് കരുണയുടെ വർഷം
            </h2>
            <a
              href='/kaniv' 
              rel="noopener noreferrer"
              className="mt-6 inline-block rounded-xl border-2 px-10 py-3 font-semibold border-gray-700 transition duration-300 transform text-black hover:text-black hover:scale-105"
            >
              Read Now
            </a>
          </div>
        </div>
      </div>

      <div class="max-w-7xl   bg-gray-50 px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
        <div class="md:grid md:grid-cols-3 md:items-center md:gap-12 xl:gap-32">
          <div class="flex flex-col md:w-2/3 lg:w-full">
            <img
              class="rounded-xl w-[400px] h-[300px] object-cover"
              src={ll}
              alt="FeaturesImage"
            />

            <p class="mt-6 text-lg text-gray-800 font-semibold text-left py-2">
              Rt. Rev. Dr. Royce Manoj Victor
            </p>
          </div>

          <div class="md:col-span-2 mt-5 sm:mt-10 lg:mt-0">
            <div class="space-y-6 sm:space-y-8">
              <div class="space-y-2 md:space-y-4">
                <h2 class="relative font-bold text-xl lg:text-xl text-[#881337]">
                  Motto of the Year 2024: "Growing Stronger, Growing Deeper,
                  Reaching Higher"
                  {/* <span class="absolute left-0 bottom-0 w-2/4 h-[2px] mt-4 bg-gradient-to-r from-[#881337] to-[#881337] transform translate-y-1"></span> */}
                </h2>
                <p class="text-gray-500 dark:text-neutral-500">
                  As we stand on the threshold of a new year, with gratitude
                  overflowing in our hearts, we reflect upon the wondrous ways
                  our Sovereign Lord has guided us since April 9, 2015. Despite
                  facing numerous challenges and shortcomings, His provision has
                  been nothing short of miraculous, enabling us to fulfill our
                  goals and mission day after day. As we anticipate the
                  opportunities and responsibilities that lie ahead, we humbly
                  rely on Divine strength and courage to deepen our
                  understanding of His mercy and broaden our vision. Together,
                  let us revive our spirits and rejuvenate our efforts,
                  embracing the mission and vision entrusted to us by the great
                  missionaries of the past. Hand in hand with their inspiring
                  legacy, let us fortify our resolve to build the Kingdom of
                  God, anchored firmly on Christ, the unshakeable cornerstone of
                  our faith.
                </p>
              </div>

              <blockquote class="relative text-lg italic text-white bg-gradient-to-r from-[#881337] to-[#d81b60] p-6 rounded-lg overflow-hidden">
                <svg
                  class="absolute w-24 h-24 text-white opacity-25 top-4 left-4 transform rotate-45"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M5 2h14c1.1 0 2 .9 2 2v16c0 1.1-.9 2-2 2H5c-1.1 0-2-.9-2-2V4c0-1.1.9-2 2-2zm7 2h2v2h-2V4zm0 4h2v2h-2V8zm-4 0h2v2H8V8zm0 4h2v2H8v-2zm0 4h2v2H8v-2z"></path>
                </svg>

                <svg
                  class="absolute w-16 h-24 text-white opacity-30 bottom-8 right-8 transform -rotate-30"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M14 2h-4v1H8v2h8V3h-2V2zm-1 9h-2V8h2v3zm0 2h-2v2h2v-2zm-2 4h2v4h-2v-4zm0-6H8v-2h4v2z"></path>
                </svg>

                <p class="relative z-10">
                  "But those who hope in the Lord will renew their strength.
                  They will soar on wings like eagles; they will run and not
                  grow weary, they will walk and not be faint."
                </p>
                <span class="block mt-4 text-sm font-semibold text-gray-200">
                  &mdash; Isaiah 40:31
                </span>
              </blockquote>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
