import React from 'react'
import img from './assets/BANNER.jpg'
export default function Hospital() {
  return (
    <div>
     <div
        className="w-full mx-auto px-9 py-8 bg-cover bg-center"
        style={{ backgroundImage: `url(${img})` }}
      >
        <div className="flex items-center text-black">
          <a
            href="/"
            className="flex font-semibold text-lg items-center text-gray-900 transition-colors duration-300"
          >
            <svg
              className="w-4 h-4 mr-2"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
            </svg>
            Home
          </a>
          <span className="mx-2">/</span>
          <h2 className="text-[#881337]">Hospitals</h2>
        </div>
      </div>
    <div class="container  max-w-7xl   mx-auto px-4 p-6 bg-white text-gray-900">
    <div class="bg-white p-6 mb-8">
        <h1 class="text-4xl font-semibold text-[#881337] mb-4">CSI Mission Hospital, Codacal</h1>
        <div class="mb-4">
            <p class="text-lg">CSI Mission Hospital, Codacal P.O., Tirur, Kerala, India, Pin - 676108</p>
            <p class="mt-2">Ph: <span class="font-medium">0494-2602415 / 2602119 / 2602015</span></p>
            <p>Email: <a href="mailto:csihca@sify.com" class="text-blue-600 underline">csihca@sify.com</a></p>
            <p><a href="http://www.csimissionhospital.com" target="_blank" class="text-blue-600 underline" rel="noreferrer">www.csimissionhospital.com</a></p>
        </div>

        <h2 class="text-3xl font-semibold text-[#881337] py-6">About CSI Mission Hospital</h2>
        <p class="text-gray-700 leading-relaxed mb-4">
            CSI Mission Hospital was established in 1970 in Tirunnavaya panchayat, a rural area in Malappuram District. This area was selected at that time by the church because health care facilities were not accessible to the majority of the population here. They had to travel to Calicut or Thrissur or Palakkad when they needed secondary care.
        </p>
        <p class="text-gray-700 leading-relaxed mb-4">
            Even now, this is the only hospital under the Malabar Diocese (and formerly under the North Kerala Diocese). The North Kerala Diocese is one of the network churches of the Christian Medical College, Vellore association, by virtue of which our students can get admission under the minority network category for the various courses at CMC. Such students have a service obligation to serve in a hospital under the respective Churches after they successfully complete their courses at CMC. So our hospital is also a boon to many such aspirants and their families.
        </p>
        <p class="text-gray-700 leading-relaxed mb-4">
            We have completed 52 years of committed service to humanity, and we are grateful to all the previous and present doctors and staff for their dedicated, sincere service in the hospital.
        </p>
        <p class="text-gray-700 leading-relaxed mb-4">
            Our Physical Medicine & Rehabilitation department is well known to a vast population far and near, as we work in collaboration with CMC, Vellore.
        </p>
        <p class="text-gray-700 leading-relaxed">
            At present, we get the services of 16 doctors (4 service obligation), and 55 staff (5 service obligation). Mr. Dhivin Paul Puthukkattil is the present Administrative Officer, and Dr. Jairaj Periyan is the Medical Superintendent.
        </p>
    </div>

    <div class=" bg-gray-50 rounded p-6 mb-8">
        <h2 class="text-3xl font-semibold text-[#881337] mb-6">Departments</h2>
        <ul class="grid grid-cols-1 md:grid-cols-2 gap-6">
            <li class="flex items-center space-x-4 bg-white p-4 rounded-lg shadow-sm hover:shadow-md">
                <div class="w-10 h-10 flex items-center justify-center bg-[#881337] text-white rounded-full">
                    <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path>
                    </svg>
                </div>
                <p class="text-gray-700 font-medium">General Medicine</p>
            </li>
            <li class="flex items-center space-x-4 bg-white p-4 rounded-lg shadow-sm hover:shadow-md">
                <div class="w-10 h-10 flex items-center justify-center bg-[#881337] text-white rounded-full">
                    <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path>
                    </svg>
                </div>
                <p class="text-gray-700 font-medium">Pulmonology</p>
            </li>
            <li class="flex items-center space-x-4 bg-white p-4 rounded-lg shadow-sm hover:shadow-md">
                <div class="w-10 h-10 flex items-center justify-center bg-[#881337] text-white rounded-full">
                    <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path>
                    </svg>
                </div>
                <p class="text-gray-700 font-medium">General Surgery</p>
            </li>
            <li class="flex items-center space-x-4 bg-white p-4 rounded-lg shadow-sm hover:shadow-md">
                <div class="w-10 h-10 flex items-center justify-center bg-[#881337] text-white rounded-full">
                    <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path>
                    </svg>
                </div>
                <p class="text-gray-700 font-medium">Pediatrics</p>
            </li>
            <li class="flex items-center space-x-4 bg-white p-4 rounded-lg shadow-sm hover:shadow-md">
                <div class="w-10 h-10 flex items-center justify-center bg-[#881337] text-white rounded-full">
                    <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path>
                    </svg>
                </div>
                <p class="text-gray-700 font-medium">Orthopedics</p>
            </li>
            <li class="flex items-center space-x-4 bg-white p-4 rounded-lg shadow-sm hover:shadow-md">
                <div class="w-10 h-10 flex items-center justify-center bg-[#881337] text-white rounded-full">
                    <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path>
                    </svg>
                </div>
                <p class="text-gray-700 font-medium">RADIOLOGY</p>
            </li>
            <li class="flex items-center space-x-4 bg-white p-4 rounded-lg shadow-sm hover:shadow-md">
                <div class="w-10 h-10 flex items-center justify-center bg-[#881337] text-white rounded-full">
                    <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path>
                    </svg>
                </div>
                <p class="text-gray-700 font-medium">E.N.T</p>
            </li>
            <li class="flex items-center space-x-4 bg-white p-4 rounded-lg shadow-sm hover:shadow-md">
                <div class="w-10 h-10 flex items-center justify-center bg-[#881337] text-white rounded-full">
                    <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path>
                    </svg>
                </div>
                <p class="text-gray-700 font-medium">Dermatology</p>
            </li>
            <li class="flex items-center space-x-4 bg-white p-4 rounded-lg shadow-sm hover:shadow-md">
                <div class="w-10 h-10 flex items-center justify-center bg-[#881337] text-white rounded-full">
                    <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path>
                    </svg>
                </div>
                <p class="text-gray-700 font-medium">Dental</p>
            </li>
            <li class="flex items-center space-x-4 bg-white p-4 rounded-lg shadow-sm hover:shadow-md">
                <div class="w-10 h-10 flex items-center justify-center bg-[#881337] text-white rounded-full">
                    <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path>
                    </svg>
                </div>
                <p class="text-gray-700 font-medium">Physiotherapy</p>
            </li>
            <li class="flex items-center space-x-4 bg-white p-4 rounded-lg shadow-sm hover:shadow-md">
                <div class="w-10 h-10 flex items-center justify-center bg-[#881337] text-white rounded-full">
                    <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path>
                    </svg>
                </div>
                <p class="text-gray-700 font-medium">Speech Therapy</p>
            </li>
            <li class="flex items-center space-x-4 bg-white p-4 rounded-lg shadow-sm hover:shadow-md">
                <div class="w-10 h-10 flex items-center justify-center bg-[#881337] text-white rounded-full">
                    <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path>
                    </svg>
                </div>
                <p class="text-gray-700 font-medium">Occupational Therapy</p>
            </li>
            
        </ul>
    </div>

    <div class="bg-white p-6 rounded-lg shadow-sm border-l-4 border-[#881337]">
    <p class="text-gray-700 leading-relaxed italic font-serif text-xl">
        "It is purely by God’s grace that we remain as a beacon of light to the population in this rural area, in spite of stiff competition by the corporate hospitals and other private hospitals that have come up in the nearby locations. Please continue to uphold us in your daily prayers."
    </p>
</div>

</div>


    </div>
  )
}
