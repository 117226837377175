import React, { useEffect, useState } from "react";
import img from "./assets/BANNER.jpg";
import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { db } from "../Config/Config";
export default function Bokkshop() {
// ================================================ Data fetching ============================================= //
  const [showData, setShowData] = useState([]);
  const fetchData = async () => {
    const q = query(collection(db, "book-shop"), orderBy("order", "asc"));
    const querySnapshot = await getDocs(q);
    const data = [];
    querySnapshot.forEach((doc) => {
      data.push({
        id: doc.id,
        ...doc.data()
      });
    });
    setShowData(data);
  };

  useEffect(() => {
    fetchData();
  }, [])

  return (
    <div>
      <div
        className="w-full mx-auto px-9 py-8 bg-cover bg-center"
        style={{ backgroundImage: `url(${img})` }}
      >
        <div className="flex items-center text-black">
          <a
            href="/"
            className="flex font-semibold text-lg items-center text-gray-900  transition-colors duration-300"
          >
            <svg
              className="w-4 h-4 mr-2"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
            </svg>
            Home
          </a>
          <span className="mx-2">/</span>
          <h2 className="text-[#881337]">BOOK SHOP</h2>
        </div>
      </div>
      {showData.map((data) => (
        <div class="grid grid-cols-1 md:grid-cols-2 py-10 px-4 gap-6">
          <div class="relative bg-white p-6 rounded-lg border border-transparent ">
            <div class="absolute inset-0 border-gradient-to-r from-[#881337] to-[#5a2222] rounded-lg"></div>
            <div class="relative">
              <div class="bg-gradient-to-r from-[#881337] to-[#5a2222] text-white py-2 px-4 rounded-t-lg">
                <h2 class="text-xl font-bold">{data.shop_name}</h2>
              </div>
              <div class="p-6 bg-white rounded-b-lg">
                <p class="text-gray-700 mb-2">
                  <strong>Manager:</strong> {data.manager}
                </p>
                <p class="text-gray-700 mb-2">
                  <strong>Contact No:</strong>{" "}
                  <a href="tel:+919388944263" class="text-blue-600 underline">
                    {data.mobile}
                  </a>
                </p>
                <p class="text-gray-700 mb-2">
                  <strong>Address:</strong> {data.address}
                </p>

                {data.facilities ? (
                  <>
                    <h3 class="text-xl font-semibold text-[#881337] mt-6 mb-4">
                      Facilities
                    </h3>
                    <ul class="list-disc list-inside text-gray-700 space-y-2">
                      {data.facilities.map((facility, index) => (
                        <li key={index}>{facility}</li>
                      ))}
                    </ul>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
