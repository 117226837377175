import React from 'react'
import Banner from '../Banner/Banner'
import Scrollnews from '../Scrollnews/Scrollnews'
import About from '../About/About'
import Status from '../Status/Status'
import News from '../News/News'
import Contact from '../Contact/Contact'
import Gallery from './Gallery'
export default function Home() {
  return (
    <div>
     <Banner />
    
     <Scrollnews/>
      <About/>
      {/* <Fb/> */}
      <Status/>
<Gallery/>
      <News/>
      {/* <Loading/> */}
      <Contact/>
   
      
    </div>
  )
}
